import { useIntl } from "react-intl";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";

const SidebarMenuMain = () => {
  const intl = useIntl();
  const getPermissions: any = localStorage.getItem("permissions");
  const permissions = getPermissions || [];

  return (
    <>
      <div data-bs-toggle="tooltip" title="Summarized information of system">
        <SidebarMenuItem
          to="/dashboard"
          icon="/media/icons/duotune/graphs/gra008.svg"
          title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
          fontIcon="bi-app-indicator"
        />
      </div>

      {permissions?.includes("view-blend-logs") === true && (
        <div data-bs-toggle="tooltip" title="Blend Summary">
          <SidebarMenuItem
            to="/blendsummary"
            title="Blend Summary"
            icon="/media/icons/duotune/general/gen031.svg"
          />
        </div>
      )}

      {permissions?.includes("view-customer-satisfaction-summary") === true && (
        <div data-bs-toggle="tooltip" title="Customer satisfaction">
          <SidebarMenuItem
            to="/customerSatisfactionSummary"
            title="Customer Satisfaction"
            icon="/media/icons/duotune/general/gen029.svg"
          />
        </div>
      )}

      {permissions?.includes("view-customer-dashboard") === true && (
        <div data-bs-toggle="tooltip" title="Customer Dashboard">
          <SidebarMenuItem
            to="/customerDashboard"
            title="Customer Dashboard"
            icon="/media/icons/duotune/general/gen031.svg"
          />
        </div>
      )}

      {/* <div data-bs-toggle="tooltip" title="Blend Summary">
              <SidebarMenuItem
                to="/ticketsummary"
                title="Ticket Summary"
                icon="/media/icons/duotune/finance/fin003.svg"
              />
            </div>
        </div> */}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-7 ls-1">
            Pages
          </span>
        </div>
      </div>
      {permissions?.includes("create-customer") === true ||
        permissions?.includes("view-customer") === true ? (
        <div data-bs-toggle="tooltip" title="Customers">
          <SidebarMenuItemWithSub
            to="/customers"
            title="Customers"
            icon="/media/icons/duotune/communication/com014.svg"
          >
            {permissions?.includes("create-customer") === true && (
              <div data-bs-toggle="tooltip" title="Form to create new customer">
                <SidebarMenuItem
                  to="/customers/create"
                  title="Create Customer"
                  hasBullet={true}
                />
              </div>
            )}
            {permissions?.includes("view-customer") === true && (
              <div data-bs-toggle="tooltip" title="Customers list">
                <SidebarMenuItem
                  to="/customers/list"
                  title="Customers List"
                  hasBullet={true}
                />
              </div>
            )}
          </SidebarMenuItemWithSub>
        </div>
      ) : (
        ""
      )}
      {permissions?.includes("create-location") === true ||
        permissions?.includes("view-location") === true ? (
        <div data-bs-toggle="tooltip" title="Locations">
          <SidebarMenuItemWithSub
            to="/locations"
            title="Locations"
            icon="/media/icons/duotune/maps/map008.svg"
          >
            {permissions?.includes("create-location") === true && (
              <div data-bs-toggle="tooltip" title="Form to create new location">
                <SidebarMenuItem
                  to="/locations/create"
                  title="Create Location"
                  hasBullet={true}
                />
              </div>
            )}
            {permissions?.includes("view-location") === true && (
              <div data-bs-toggle="tooltip" title="Locations list">
                <SidebarMenuItem
                  to="/locations/list"
                  title="Locations List"
                  hasBullet={true}
                />
              </div>
            )}
            {permissions?.includes("view-location") === true && (
              <div data-bs-toggle="tooltip" title="Location flavor pricing chart">
                <SidebarMenuItem
                  to="/locations/flavorPrice"
                  title="Location flavor pricing chart"
                  hasBullet={true}
                />
              </div>
            )}
          </SidebarMenuItemWithSub>
        </div>
      ) : (
        ""
      )}
      {permissions?.includes("create-machine") === true ||
        permissions?.includes("view-machine") === true ? (
        <div data-bs-toggle="tooltip" title="Machines">
          <SidebarMenuItemWithSub
            to="/machines"
            title="Machines"
            icon="/media/icons/duotune/electronics/elc002.svg"
          >
            {permissions?.includes("create-machine") === true && (
              <div data-bs-toggle="tooltip" title="Form to create new machine">
                <SidebarMenuItem
                  to="/machines/create"
                  title="Create Machine"
                  hasBullet={true}
                />
              </div>
            )}
            {permissions?.includes("view-machine") === true && (
              <div data-bs-toggle="tooltip" title="Machines list">
                <SidebarMenuItem
                  to="/machines/list"
                  title="Machines List"
                  hasBullet={true}
                />
              </div>
            )}
          </SidebarMenuItemWithSub>
        </div>
      ) : (
        ""
      )}
      {permissions?.includes("create-logJob") === true ||
        permissions?.includes("view-logJob") === true ? (
        <div data-bs-toggle="tooltip" title="Jobs">
          <SidebarMenuItemWithSub
            to="/job"
            title="Jobs"
            icon="/media/icons/duotune/finance/fin006.svg"
          >
            {permissions?.includes("create-logJob") === true && (
              <div data-bs-toggle="tooltip" title="Form to create new job">
                <SidebarMenuItem
                  to="/job/create"
                  title="Create Job"
                  hasBullet={true}
                />
              </div>
            )}
            {permissions?.includes("view-logJob") === true && (
              <div data-bs-toggle="tooltip" title="Jobs list">
                <SidebarMenuItem
                  to="/job/list"
                  title="Jobs List"
                  hasBullet={true}
                />
              </div>
            )}
          </SidebarMenuItemWithSub>
        </div>
      ) : (
        ""
      )}

      {permissions?.includes("create-ticket") === true ||
        permissions?.includes("view-ticket") === true ||
        permissions?.includes("schedule-ticket") === true ? (
        <div data-bs-toggle="tooltip" title="Tickets">
          <SidebarMenuItemWithSub
            to="/tickets"
            title="Tickets"
            icon="/media/icons/duotune/finance/fin003.svg"
          >
            {permissions?.includes("create-ticket") === true && (
              <div data-bs-toggle="tooltip" title="Form to create new ticket">
                <SidebarMenuItem
                  to="/tickets/create"
                  title="Create Ticket"
                  hasBullet={true}
                />
              </div>
            )}
            {permissions?.includes("view-ticket") === true && (
              <div data-bs-toggle="tooltip" title="Logs for ticket maintenance">
                <SidebarMenuItem
                  to="/tickets/list"
                  title="Ticket Logs"
                  hasBullet={true}
                />
              </div>
            )}
            {permissions?.includes("schedule-ticket") === true && (
              <div data-bs-toggle="tooltip" title="Schedule tickets">
                <SidebarMenuItem
                  to="/tickets/calender"
                  title="Schedule Tickets"
                  hasBullet={true}
                />
              </div>)}
          </SidebarMenuItemWithSub>
        </div>
      ) : (
        ""
      )}

      {/* {permissions?.includes("create-survey") === true ||
        permissions?.includes("view-survey") === true ? (
        <div data-bs-toggle="tooltip" title="Survey">
          <SidebarMenuItemWithSub
            to="/masters/survey"
            title="Survey"
            icon="/media/icons/duotune/text/txt009.svg"
          >
            {permissions?.includes("create-survey") === true && (
              <div
                data-bs-toggle="tooltip"
                title="Form to create Survey"
              >
                <SidebarMenuItem
                  to="/masters/survey/create"
                  title="Create Survey"
                  hasBullet={true}
                />
              </div>
            )}
            {permissions?.includes("view-survey") === true && (
              <div data-bs-toggle="tooltip" title="Survey list">
                <SidebarMenuItem
                  to="/masters/survey/list"
                  title="Survey List"
                  hasBullet={true}
                />
              </div>
            )}
          </SidebarMenuItemWithSub>
        </div>
      ) : (
        ""
      )}

      {permissions?.includes("create-events") === true ||
        permissions?.includes("view-events") === true ? (
        <div data-bs-toggle="tooltip" title="Event">
          <SidebarMenuItemWithSub
            to="/masters/events"
            title="Events"
            icon="/media/icons/duotune/general/gen014.svg"
          >
            {permissions?.includes("create-events") === true && (
              <div
                data-bs-toggle="tooltip"
                title="Form to create Event"
              >
                <SidebarMenuItem
                  to="/masters/events/create"
                  title="Create Event"
                  hasBullet={true}
                />
              </div>
            )}
            {permissions?.includes("view-events") === true && (
              <div data-bs-toggle="tooltip" title="Event list">
                <SidebarMenuItem
                  to="/masters/events/list"
                  title="Event List"
                  hasBullet={true}
                />
              </div>
            )}
          </SidebarMenuItemWithSub>
        </div>
      ) : (
        ""
      )} */}

      {permissions?.includes("view-blend-logs") === true && (
        <div data-bs-toggle="tooltip" title="Blend Logs">
          <div data-bs-toggle="tooltip" title="Blend Logs">
            <SidebarMenuItem
              to="/blenglogs"
              title="Blend Logs"
              icon="/media/icons/duotune/general/gen031.svg"
            />
          </div>
        </div>
      )}
        <div data-bs-toggle="tooltip" title="Work Orders">
          <div data-bs-toggle="tooltip" title="Work Orders">
            <SidebarMenuItem
              to="/workOrder"
              title="Work Orders"
              icon="/media/icons/duotune/general/gen063.svg"
            />
          </div>
        </div>
      {permissions?.includes("create-user") === true ||
        permissions?.includes("view-user") === true ||
        permissions?.includes("create-role") === true ||
        permissions?.includes("view-role") === true ? (
        <div data-bs-toggle="tooltip" title="Users">
          <SidebarMenuItemWithSub
            to="/users"
            title="Users"
            icon="/media/icons/duotune/communication/com013.svg"
          >
            {permissions?.includes("create-user") === true && (
              <div data-bs-toggle="tooltip" title="Form to create new user">
                <SidebarMenuItem
                  to="/users/create"
                  title="Create User"
                  hasBullet={true}
                />
              </div>
            )}
            {permissions?.includes("view-user") === true && (
              <div data-bs-toggle="tooltip" title="Users list">
                <SidebarMenuItem
                  to="/users/list"
                  title="Users List"
                  hasBullet={true}
                />
              </div>
            )}
            {permissions?.includes("create-role") === true ||
              permissions?.includes("view-role") === true ? (
              <div data-bs-toggle="tooltip" title="Roles">
                <SidebarMenuItemWithSub
                  to="/users/role"
                  title="Roles"
                  hasBullet={true}
                >
                  {permissions?.includes("create-role") === true && (
                    <div
                      data-bs-toggle="tooltip"
                      title="Form to create new role"
                    >
                      <SidebarMenuItem
                        to="/users/role/create"
                        title="Create Role"
                        hasBullet={true}
                      />
                    </div>
                  )}
                  {permissions?.includes("view-role") === true && (
                    <div data-bs-toggle="tooltip" title="Roles list">
                      <SidebarMenuItem
                        to="/users/role/list"
                        title="Roles List"
                        hasBullet={true}
                      />
                    </div>
                  )}
                </SidebarMenuItemWithSub>
              </div>
            ) : (
              ""
            )}
          </SidebarMenuItemWithSub>
        </div>
      ) : (
        ""
      )}

      {permissions?.includes("create-issue-reported") === true ||
        permissions?.includes("create-machine-service") === true ||
        permissions?.includes("create-marketing") === true ||
        permissions?.includes("create-operation") === true ||
        permissions?.includes("view-issue-reported") === true ||
        permissions?.includes("view-machine-service") === true ||
        permissions?.includes("view-marketing") === true ||
        permissions?.includes("view-operation") === true ||
        permissions?.includes("create-marketing-material") === true ||
        permissions?.includes("view-marketing-material") === true ||
        permissions?.includes("create-distributor") === true ||
        permissions?.includes("view-distributor") === true ||
        permissions?.includes("create-deployment-type") === true ||
        permissions?.includes("view-deployment-type") === true ||
        permissions?.includes("create-region") === true ||
        permissions?.includes("view-region") === true ||
        permissions?.includes("create-market-segment") === true ||
        permissions?.includes("swap-deployment-manager-location") ||
        permissions?.includes("view-market-segment") === true ||
        permissions?.includes("create-business-model") === true ||
        permissions?.includes("view-business-model") === true ||
        permissions?.includes("create-board") === true ||
        permissions?.includes("view-board") === true ||
        permissions?.includes("create-blender") === true ||
        permissions?.includes("view-blender") === true ||
        permissions?.includes("create-machine-serial-number") === true ||
        permissions?.includes("view-machine-serial-number") === true ||
        permissions?.includes("create-diagnosis") === true ||
        permissions?.includes("view-diagnosis") === true ||
        permissions?.includes("create-cup-holder") === true ||
        permissions?.includes("view-cup-holder") === true ||
        permissions?.includes("create-gas-supplier") === true ||
        permissions?.includes("view-gas-supplier") === true ||
        permissions?.includes("create-location-type") === true ||
        permissions?.includes("view-location-type") === true ? (
        <div data-bs-toggle="tooltip" title="Masters">
          <SidebarMenuItemWithSub
            to="/masters"
            title="Masters"
            icon="/media/icons/duotune/ecommerce/ecm008.svg"
          >
            {permissions?.includes("create-blender") === true ||
              permissions?.includes("view-blender") === true ? (
              <div data-bs-toggle="tooltip" title="Blender">
                <SidebarMenuItemWithSub
                  to="/masters/blenders"
                  title="Blenders"
                  hasBullet={true}
                >
                  {permissions?.includes("create-blender") === true && (
                    <div
                      data-bs-toggle="tooltip"
                      title="Form to create Blender"
                    >
                      <SidebarMenuItem
                        to="/masters/blenders/create"
                        title="Create Blender"
                        hasBullet={true}
                      />
                    </div>
                  )}
                  {permissions?.includes("view-blender") === true && (
                    <div data-bs-toggle="tooltip" title="Blender List">
                      <SidebarMenuItem
                        to="/masters/blenders/list"
                        title="Blenders List"
                        hasBullet={true}
                      />
                    </div>
                  )}
                </SidebarMenuItemWithSub>
              </div>
            ) : (
              ""
            )}
            {permissions?.includes("create-board") === true ||
              permissions?.includes("view-board") === true ? (
              <div data-bs-toggle="tooltip" title="Board">
                <SidebarMenuItemWithSub
                  to="/masters/board "
                  title="Board"
                  hasBullet={true}
                >
                  {permissions?.includes("create-board") === true && (
                    <div data-bs-toggle="tooltip" title="Form to create Board">
                      <SidebarMenuItem
                        to="/masters/board/create"
                        title="Create Board"
                        hasBullet={true}
                      />
                    </div>
                  )}
                  {permissions?.includes("view-board") === true && (
                    <div data-bs-toggle="tooltip" title="Board List">
                      <SidebarMenuItem
                        to="/masters/board/list"
                        title="Board List"
                        hasBullet={true}
                      />
                    </div>
                  )}
                </SidebarMenuItemWithSub>
              </div>
            ) : (
              ""
            )}
            {permissions?.includes("create-business-model") === true ||
              permissions?.includes("view-business-model") === true ? (
              <div data-bs-toggle="tooltip" title="Business Model">
                <SidebarMenuItemWithSub
                  to="/masters/business-model"
                  title="Business Model"
                  hasBullet={true}
                >
                  {permissions?.includes("create-business-model") === true && (
                    <div
                      data-bs-toggle="tooltip"
                      title="Form to create business model"
                    >
                      <SidebarMenuItem
                        to="/masters/business-model/create"
                        title="Create Business Model"
                        hasBullet={true}
                      />
                    </div>
                  )}
                  {permissions?.includes("view-business-model") === true && (
                    <div data-bs-toggle="tooltip" title="Business Model list">
                      <SidebarMenuItem
                        to="/masters/business-model/list"
                        title="Business Model List"
                        hasBullet={true}
                      />
                    </div>
                  )}
                </SidebarMenuItemWithSub>
              </div>
            ) : (
              ""
            )}

            {permissions?.includes("create-diagnosis") === true ||
              permissions?.includes("view-diagnosis") === true ? (
              <div data-bs-toggle="tooltip" title="Diagnosis">
                <SidebarMenuItemWithSub
                  to="/masters/diagnosis"
                  title="Diagnosis"
                  hasBullet={true}
                >
                  {permissions?.includes("create-diagnosis") === true && (
                    <div
                      data-bs-toggle="tooltip"
                      title="Form to create diagnosis"
                    >
                      <SidebarMenuItem
                        to="/masters/diagnosis/create"
                        title="Create Diagnosis"
                        hasBullet={true}
                      />
                    </div>
                  )}
                  {permissions?.includes("view-diagnosis") === true && (
                    <div data-bs-toggle="tooltip" title="Diagnosis list">
                      <SidebarMenuItem
                        to="/masters/diagnosis/list"
                        title="Diagnosis List"
                        hasBullet={true}
                      />
                    </div>
                  )}
                </SidebarMenuItemWithSub>
              </div>
            ) : (
              ""
            )}
            {/* {permissions?.includes("create-deployment-type") === true ||
              permissions?.includes("view-deployment-type") === true ? (
              <div data-bs-toggle="tooltip" title="Deployment Types">
                <SidebarMenuItemWithSub
                  to="/masters/deployment-type"
                  title="Deployment Types"
                  hasBullet={true}
                >
                  {permissions?.includes("create-deployment-type") === true && (
                    <div
                      data-bs-toggle="tooltip"
                      title="Form to create deployment type"
                    >
                      <SidebarMenuItem
                        to="/masters/deployment-type/create"
                        title="Create Deployment Type"
                        hasBullet={true}
                      />
                    </div>
                  )}
                  {permissions?.includes("view-deployment-type") === true && (
                    <div data-bs-toggle="tooltip" title="Deployment Types list">
                      <SidebarMenuItem
                        to="/masters/deployment-type/list"
                        title="Deployment Types List"
                        hasBullet={true}
                      />
                    </div>
                  )}
                </SidebarMenuItemWithSub>
              </div>
            ) : (
              ""
            )} */}
            {permissions?.includes("create-distributor") === true ||
              permissions?.includes("view-distributor") === true ? (
              <div data-bs-toggle="tooltip" title="Distributors">
                <SidebarMenuItemWithSub
                  to="/masters/distributors"
                  title="Distributors"
                  hasBullet={true}
                >
                  {permissions?.includes("create-distributor") === true && (
                    <div
                      data-bs-toggle="tooltip"
                      title="Form to create distributor"
                    >
                      <SidebarMenuItem
                        to="/masters/distributors/create"
                        title="Create Distributor"
                        hasBullet={true}
                      />
                    </div>
                  )}
                  {permissions?.includes("view-distributor") === true && (
                    <div data-bs-toggle="tooltip" title="Distributors list">
                      <SidebarMenuItem
                        to="/masters/distributors/list"
                        title="Distributors List"
                        hasBullet={true}
                      />
                    </div>
                  )}
                </SidebarMenuItemWithSub>
              </div>
            ) : (
              ""
            )}
            {permissions?.includes("create-issue-reported") === true ||
              permissions?.includes("view-issue-reported") === true ? (
              <div data-bs-toggle="tooltip" title="Issue Reported">
                <SidebarMenuItemWithSub
                  to="/masters/issue"
                  title="Issue Reported"
                  hasBullet={true}
                >
                  {permissions?.includes("create-issue-reported") === true && (
                    <div
                      data-bs-toggle="tooltip"
                      title="Form to create issue reported"
                    >
                      <SidebarMenuItem
                        to="/masters/issues/create"
                        title="Create Issue Reported"
                        hasBullet={true}
                      />
                    </div>
                  )}
                  {permissions?.includes("view-issue-reported") === true && (
                    <div data-bs-toggle="tooltip" title="Issue reported list">
                      <SidebarMenuItem
                        to="/masters/issues/list"
                        title="Issue Reported List"
                        hasBullet={true}
                      />
                    </div>
                  )}
                </SidebarMenuItemWithSub>
              </div>
            ) : (
              ""
            )}
            {permissions?.includes("create-region") === true ||
              permissions?.includes("view-region") === true ? (
              <div data-bs-toggle="tooltip" title="Location Regions">
                <SidebarMenuItemWithSub
                  to="/masters/region"
                  title="Location Regions"
                  hasBullet={true}
                >
                  {permissions?.includes("create-region") === true && (
                    <div
                      data-bs-toggle="tooltip"
                      title="Form to create location region"
                    >
                      <SidebarMenuItem
                        to="/masters/region/create"
                        title="Create Location Region"
                        hasBullet={true}
                      />
                    </div>
                  )}
                  {permissions?.includes("view-region") === true && (
                    <div data-bs-toggle="tooltip" title="Location Regions list">
                      <SidebarMenuItem
                        to="/masters/region/list"
                        title="Location Regions List"
                        hasBullet={true}
                      />
                    </div>
                  )}
                </SidebarMenuItemWithSub>
              </div>
            ) : (
              ""
            )}
            {permissions?.includes("create-location-type") === true ||
              permissions?.includes("view-location-type") === true ? (
              <div data-bs-toggle="tooltip" title="Location Types">
                <SidebarMenuItemWithSub
                  to="/masters/location-type"
                  title="Location Types"
                  hasBullet={true}
                >
                  {permissions?.includes("create-location-type") === true && (
                    <div
                      data-bs-toggle="tooltip"
                      title="Form to create location type"
                    >
                      <SidebarMenuItem
                        to="/masters/location-type/create"
                        title="Create Location Type"
                        hasBullet={true}
                      />
                    </div>
                  )}
                  {permissions?.includes("view-location-type") === true && (
                    <div data-bs-toggle="tooltip" title="Location Types list">
                      <SidebarMenuItem
                        to="/masters/location-type/list"
                        title="Location Types List"
                        hasBullet={true}
                      />
                    </div>
                  )}
                </SidebarMenuItemWithSub>
              </div>
            ) : (
              ""
            )}
            {permissions?.includes("create-machine-serial-number") === true ||
              permissions?.includes("view-machine-serial-number") === true ? (
              <div data-bs-toggle="tooltip" title="Machine Serial Number">
                <SidebarMenuItemWithSub
                  to="/masters/machine-serial-number"
                  title="Machine Serial Number"
                  hasBullet={true}
                >
                  {permissions?.includes("create-machine-serial-number") === true && (
                    <div
                      data-bs-toggle="tooltip"
                      title="Form to create Machine Serial Number"
                    >
                      <SidebarMenuItem
                        to="/masters/machine-serial-number/create"
                        title="Import Machine Serial Number"
                        hasBullet={true}
                      />
                    </div>
                  )}
                  {permissions?.includes("view-machine-serial-number") === true && (
                    <div data-bs-toggle="tooltip" title="Machine Serial Number List">
                      <SidebarMenuItem
                        to="/masters/machine-serial-number/list"
                        title="Machine Serial Number List"
                        hasBullet={true}
                      />
                    </div>
                  )}
                </SidebarMenuItemWithSub>
              </div>
            ) : (
              ""
            )}
            {permissions?.includes("create-machine-service") === true ||
              permissions?.includes("view-machine-service") === true ? (
              <div data-bs-toggle="tooltip" title="Machine Services">
                <SidebarMenuItemWithSub
                  to="/masters/services"
                  title="Machine Services"
                  hasBullet={true}
                >
                  {permissions?.includes("create-machine-service") === true && (
                    <div
                      data-bs-toggle="tooltip"
                      title="Form to create machine service"
                    >
                      <SidebarMenuItem
                        to="/masters/services/create"
                        title="Create Machine Service"
                        hasBullet={true}
                      />
                    </div>
                  )}
                  {permissions?.includes("view-machine-service") === true && (
                    <div data-bs-toggle="tooltip" title="Machine services list">
                      <SidebarMenuItem
                        to="/masters/services/list"
                        title="Machine Services List"
                        hasBullet={true}
                      />
                    </div>
                  )}
                </SidebarMenuItemWithSub>
              </div>
            ) : (
              ""
            )}
            {permissions?.includes("create-market-segment") === true ||
              permissions?.includes("view-market-segment") === true ? (
              <div data-bs-toggle="tooltip" title="Market Segments">
                <SidebarMenuItemWithSub
                  to="/masters/market-segment"
                  title="Market Segments"
                  hasBullet={true}
                >
                  {permissions?.includes("create-market-segment") === true && (
                    <div
                      data-bs-toggle="tooltip"
                      title="Form to create market segment"
                    >
                      <SidebarMenuItem
                        to="/masters/market-segment/create"
                        title="Create Market Segment"
                        hasBullet={true}
                      />
                    </div>
                  )}
                  {permissions?.includes("view-market-segment") === true && (
                    <div data-bs-toggle="tooltip" title="Market Segments list">
                      <SidebarMenuItem
                        to="/masters/market-segment/list"
                        title="Market Segments List"
                        hasBullet={true}
                      />
                    </div>
                  )}
                </SidebarMenuItemWithSub>
              </div>
            ) : (
              ""
            )}
            {permissions?.includes("create-marketing-material") === true ||
              permissions?.includes("view-marketing-material") === true ? (
              <div data-bs-toggle="tooltip" title="Marketing Materials">
                <SidebarMenuItemWithSub
                  to="/masters/marketing-material"
                  title="Marketing Materials"
                  hasBullet={true}
                >
                  {permissions?.includes("create-marketing-material") ===
                    true && (
                      <div
                        data-bs-toggle="tooltip"
                        title="Form to create marketing material"
                      >
                        <SidebarMenuItem
                          to="/masters/marketing-material/create"
                          title="Create Marketing Material"
                          hasBullet={true}
                        />
                      </div>
                    )}
                  {permissions?.includes("view-marketing-material") ===
                    true && (
                      <div
                        data-bs-toggle="tooltip"
                        title="Marketing materials list"
                      >
                        <SidebarMenuItem
                          to="/masters/marketing-material/list"
                          title="Marketing Materials List"
                          hasBullet={true}
                        />
                      </div>
                    )}
                </SidebarMenuItemWithSub>
              </div>
            ) : (
              ""
            )}
            {permissions?.includes("create-marketing") === true ||
              permissions?.includes("view-marketing") === true ? (
              <div data-bs-toggle="tooltip" title="Marketings">
                <SidebarMenuItemWithSub
                  to="/masters/marketing"
                  title="Marketings"
                  hasBullet={true}
                >
                  {permissions?.includes("create-marketing") === true && (
                    <div
                      data-bs-toggle="tooltip"
                      title="Form to create marketing"
                    >
                      <SidebarMenuItem
                        to="/masters/marketing/create"
                        title="Create Marketing"
                        hasBullet={true}
                      />
                    </div>
                  )}
                  {permissions?.includes("view-marketing") === true && (
                    <div data-bs-toggle="tooltip" title="Marketings list">
                      <SidebarMenuItem
                        to="/masters/marketing/list"
                        title="Marketings List"
                        hasBullet={true}
                      />
                    </div>
                  )}
                </SidebarMenuItemWithSub>
              </div>
            ) : (
              ""
            )}
            {permissions?.includes("create-operation") === true ||
              permissions?.includes("view-operation") === true ? (
              <div data-bs-toggle="tooltip" title="Operations">
                <SidebarMenuItemWithSub
                  to="/masters/operations"
                  title="Operations"
                  hasBullet={true}
                >
                  {permissions?.includes("create-operation") === true && (
                    <div
                      data-bs-toggle="tooltip"
                      title="Form to create operation"
                    >
                      <SidebarMenuItem
                        to="/masters/operations/create"
                        title="Create Operation"
                        hasBullet={true}
                      />
                    </div>
                  )}
                  {permissions?.includes("view-operation") === true && (
                    <div data-bs-toggle="tooltip" title="Operations list">
                      <SidebarMenuItem
                        to="/masters/operations/list"
                        title="Operations List"
                        hasBullet={true}
                      />
                    </div>
                  )}
                </SidebarMenuItemWithSub>
              </div>
            ) : (
              ""
            )}
            {permissions?.includes("swap-deployment-manager-location") ===
              true && (
                <div data-bs-toggle="tooltip" title="Replace regional manager">
                  <SidebarMenuItem
                    to="/masters/replace-manager"
                    hasBullet={true}
                    title="Replace Regional Manager"
                  />
                </div>
              )}
            {permissions?.includes("create-ticket-source") === true ||
              permissions?.includes("view-ticket-source") === true ? (
              <div data-bs-toggle="tooltip" title="Ticket Sources">
                <SidebarMenuItemWithSub
                  to="/masters/ticket-source"
                  title="Ticket Sources"
                  hasBullet={true}
                >
                  {permissions?.includes("create-ticket-source") === true && (
                    <div
                      data-bs-toggle="tooltip"
                      title="Form to create ticket source"
                    >
                      <SidebarMenuItem
                        to="/masters/ticket-source/create"
                        title="Create Ticket Source"
                        hasBullet={true}
                      />
                    </div>
                  )}
                  {permissions?.includes("view-ticket-source") === true && (
                    <div data-bs-toggle="tooltip" title="Ticket Sources list">
                      <SidebarMenuItem
                        to="/masters/ticket-source/list"
                        title="Ticket Sources List"
                        hasBullet={true}
                      />
                    </div>
                  )}
                </SidebarMenuItemWithSub>
              </div>
            ) : (
              ""
            )}
             {permissions?.includes("create-cup-holder") === true ||
              permissions?.includes("view-cup-holder") === true ? (
             <SidebarMenuItemWithSub
                title="Cup Holders"
                to="/masters/cup-holder"
                hasBullet={true}
              >
                 {permissions?.includes("create-cup-holder") === true && (
                  <SidebarMenuItem
                    title="Create Cup Holder"
                    to="/masters/cup-holder/create"
                    hasBullet={true}
                  />
                  )}
                  {permissions?.includes("view-cup-holder") === true && (
                  <SidebarMenuItem
                    title="Cup Holder List"
                    to="/masters/cup-holder/list"
                    hasBullet={true}
                  />
                  )}
                </SidebarMenuItemWithSub>
            ) : (
              ""
            )}
            
            {permissions?.includes("create-gas-supplier") === true ||
              permissions?.includes("view-gas-supplier") === true ? (
              <div data-bs-toggle="tooltip" title="Gas Supplier">
                <SidebarMenuItemWithSub
                  to="/masters/gas-supplier"
                  title="Gas Supplier"
                  hasBullet={true}
                >
                  {permissions?.includes("create-gas-supplier") === true && (
                    <div
                      data-bs-toggle="tooltip"
                      title="Form to create Gas Supplier"
                    >
                      <SidebarMenuItem
                        to="/masters/gas-supplier/create"
                        title="Create Gas Supplier"
                        hasBullet={true}
                      />
                    </div>
                  )}
                  {permissions?.includes("view-gas-supplier") === true && (
                    <div data-bs-toggle="tooltip" title="Gas Supplier list">
                      <SidebarMenuItem
                        to="/masters/gas-supplier/list"
                        title="Gas Supplier List"
                        hasBullet={true}
                      />
                    </div>
                  )}
                </SidebarMenuItemWithSub>
              </div>
            ) : (
              ""
            )}
            
            {permissions?.includes("view-settings") === true && (
              <div data-bs-toggle="tooltip" title="Settings">
                <div data-bs-toggle="tooltip" title="Settings">
                  <SidebarMenuItem
                    to="/settingPage"
                    title="Settings"
                    hasBullet={true}
                  />
                </div>
              </div>
            )}
          </SidebarMenuItemWithSub>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export { SidebarMenuMain };
