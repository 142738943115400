import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import Select from "react-select";
import { AUTH_LOCAL_STORAGE_KEY } from "../../../app/modules/auth";
import { Toaster } from "react-hot-toast";
import Moment from "moment";
import $ from "jquery";
import { Component, createRef } from "react";
import {
  getMachines,
  updateMachineStatus, getHardwareVersionsList, getMachinesNameAndSerials, getMachineStatusList
} from "../../models/_machine";
import {
  getLocationsForFilter,
  getMarketSegment,
  getUsers,
  getRegion,
} from "../../models/_location";
import { KTSVG } from "../../../_metronic/helpers";
import _ from "lodash";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { DownloadExcel } from "../ExcelHelper";
import ReactSelect from "react-select";
import { EditMachine } from './EditMachine';
import ChangeLocationModal from './ChangeLocationModal'
import AssignLocationModal from './AssignLocationModal'
import QrcodeModal from './QrcodeModal'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import MultiSelect from "../../../_metronic/helpers/MySelect";
import clsx from "clsx";
import { getBlendersList } from "../../models/_blenders";
import { getBoardsList } from "../../models/_board";
import { getModuleDataDisplaySettingByKey, updateModuleDataDisplaySettingByKey } from "../../models/_user";
import ViewStatusModel from "./ViewStatusModel"
import { Option } from '../../../_metronic/helpers/MySelect';
import Columns from '../../../_metronic/helpers/Columns';
import { getCupHoldersList } from "../../models/_cupHolders";
import { Tooltip } from "react-tooltip";
import moment from "moment";

class MachinesList extends Component<any, any> {
  readonly props: any;
  constructor(props: any, state: any) {
    super(props);
    let searchParams = new URLSearchParams(window.location.search);
    const currentPage= searchParams.get('page');
    const fromDate = searchParams.get('from_date');
    const toDate = searchParams.get('to_date');
    const applyDateFilterOn = searchParams.get('applyDate');
    const macAddress = searchParams.get('mac_address');
    const machineName = searchParams.get('name');
    const serialNumber = searchParams.get('serial_number');
    const status = searchParams.get('is_active');
    const retired = searchParams.get('is_retired');
    const isVip = searchParams.get('is_vip');
    const isPolyfuses = searchParams.get('is_polyfuses');
    const inStock = searchParams.get('inStock');

    const addHouseClamps = searchParams.get('addHouseClamps');

    const locationName = searchParams.get('location_id');
    const locationNameArray = locationName ? locationName.split(',').map((x) => x) : "";

    const boardId = searchParams.get('board_id');
    const boardIdArray = boardId ? boardId.split(',').map((x) => x) : "";

    const cupHolderId = searchParams.get('cup_holder_id');
    const cupHolderIdArray = cupHolderId ? cupHolderId.split(',').map((x) => x) : "";

    const blenderId = searchParams.get('blender_id');
    const blenderIdArray = blenderId ? blenderId.split(',').map((x) => x) : "";

    const hardwareVersion = searchParams.get('hardware_version');
    const replaceNewTubeDoors1mmShorter = searchParams.get('replace_new_tube_doors_1mm_shorter');

    const deploymentManagerIds = searchParams.get('deploymentManagerId');
    const deploymentManagerIdArray = deploymentManagerIds ? deploymentManagerIds.split(',').map((x) => x) : "";
    
    const regionId = searchParams.get('regionId');
    const regionIdArray = regionId ? regionId.split(',').map((x) => x) : "";

    const marketSegmentId = searchParams.get('marketSegmentId');
    const marketSegmentIdArray = marketSegmentId ? marketSegmentId.split(',').map((x) => x) : "";

    const machineStatusId = searchParams.get('machine_status_id');
    const machineStatusIdArray = machineStatusId ? machineStatusId.split(',').map((x) => x) : "";

    this.state = {
      resetStatus: createRef(),
      resetFrom: createRef(),
      resetTo: createRef(),
      resetMechanicalHardwareVersion: createRef(),
      resetAddHouseClamps: createRef(),
      resetIsVip: createRef(),
      resetIsPolyfuses: createRef(),
      filterToDate: toDate ?? "",
      filterFromDate: fromDate ?? "",
      filterManager: "",
      filterBoard:boardIdArray ?? "",
      filterCupHolder: cupHolderIdArray ?? "",
      filterBlender: blenderIdArray ?? "",
      filterStatus:status ?? "true",
      filterReplace: replaceNewTubeDoors1mmShorter ?? "",
      filterRetired: retired ?? 'all',
      filterLocation:locationNameArray ?? "",
      filterAddHouseClamps: addHouseClamps ?? null,
      filterIsVip : isVip ?? null,
      filterIsPolyfuses : isPolyfuses ?? "",
      filterMacAddress: macAddress ?? "",
      locationSelected: null,
      filterMarketSegement: marketSegmentIdArray ?? [],
      filterMechanicalHardwareVersion: hardwareVersion ?? "",
      filterName:machineName ?? "",
      filterSerialNumber: serialNumber ?? "",
      filterRegionId: regionIdArray ?? [],
      filterDeploymentManagerId: deploymentManagerIdArray ?? [],
      filterMachineStatus: machineStatusIdArray ?? "",
      addLoading: false,
      filterLoading: false,
      downloadLoading: false,
      qrcodeModal: false,
      machineEditModal: false,
      loading: false,
      machineList: [],
      machineListObj: {},
      managerSelected: null,
      blenderSelected: null,
      boardSelected: null,
      cupHolderSelected:null,
      changeLocationModal: false,
      assignLocationModalOpen: false,
      viewStatusModelOpen: false,
      machineStatusSelected: null,
      hardwareVersionsList: [],
      locationDB: [],
      locationRegionDB: [],
      blenderDB: [],
      boardDB: [],
      cupHolderDB:[],
      managerDB: [],
      machinesDB: [],
      machineStatusDB: [],
      marketSegmentDB: [],
      marketSegmentIdSelected: [],
      applyDateFilterOn:applyDateFilterOn ?? "",
      onlyRetired: false,
      filterInStock: inStock ?? "",
      inStock: inStock ?? "",
      locationRegionSelected: null,
      deploymentManagerSelected: null,
      finalUrl:"",
      page: currentPage ?? 0,
      perPage: 50,
      sorting: "ASC",
      statusDB: [
        {
          id: "true",
          name: "Active",
        },
        {
          id: "false",
          name: "Inactive",
        },
        {
          id: "all",
          name: "All"
        }
      ],
      ReplaceDB: [
        {
          id: true,
          name: "True",
        },
        {
          id: false,
          name: "False",
        },
      ],
      addHouseClampsDB: [
        {
          id: "true",
          name: "Yes",
        },
        {
          id: "false",
          name: "No",
        },
      ],
      isVipDB: [
        {
          id: "true",
          name: "Yes",
        },
        {
          id: "false",
          name: "No"
        },
        {
          id: "all",
          name: "All"
        }
      ],
      moduleKeyName: 'machine_list',
      selectedColumns: [],
      columnData: [
        { id: 0, label: 'Machine Id', key: 'index' },
        { id: 1, label: 'Location Name', key: 'locationName' },
        { id: 2, label: 'Mac Address', key: 'macAddress' },
        { id: 3, label: 'Serial Number', key: 'serialNumber' },
        { id: 4, label: 'Machine Name', key: 'machineName' },
        { id: 5, label: 'Deployment Date', key: 'deploymentDate' },
        { id: 6, label: 'Deployed By', key: 'deployedBy' },
        { id: 7, label: 'Machine Status', key: 'machineStatus' },
        { id: 8, label: 'Remark', key: 'remark' },
        { id: 9, label: 'Software Version', key: 'softwareVersion' },
        { id: 10, label: 'Mechanical Hardware Version', key: 'hardwareVersion' },
        { id: 11, label: 'Replace new tube doors -1mm shorter', key: 'replaceNewTubeDoors1mmShorter' },
        { id: 12, label: 'Is Polyfuses?', key: 'isPolyfuses' },
        { id: 13, label: 'Created At', key: 'createdAt' },
        { id: 14, label: 'Retired At', key: 'retiredAt' },
        { id: 15, label: 'Retired By', key: 'retiredBy' },
        { id: 16, label: 'Blender', key: 'blender' },
        { id: 17, label: 'Board', key: 'board' },
        { id: 18, label: 'Last Sanitize At', key: 'lastSanitizeTime' },
        { id: 19, label: 'Last Heartbeat At', key: 'lastHearbeatSyncAt' },
        { id: 20, label: 'Last Sanitizer Replaced At', key: 'lastSanitizerReplacedAt' },
        { id: 21, label: 'Last Filter Replaced At', key: 'lastFilterReplacedAt' },
        { id: 22, label: 'Connection Status', key: 'connectionStatus' },
        { id: 23, label: 'Is Vip', key: 'isVip' },
        { id: 24, label: 'Site Status', key: 'isActive' },
        { id: 25, label: 'Action', key: 'action' },
        { id: 26, label: 'CupHolder', key: 'cupHolder' },
      ]
    };
  }

  async componentWillMount() { }

  async componentDidMount() {
    // const storedSorting = localStorage.getItem('sorting');
    // if (storedSorting) {
    //   this.setState({ sorting: storedSorting });
    // }
    var _ = this;
    $(document).ready(function () {
      $("body").on("click", ".editBtn", function () {
        const record = $(this).attr("data-recordId");
        _.setState({
          machineEditModal: true,
          currentMachineId: record
        })
      });
      $("body").on("click", ".qrCodeBtn", function () {
        const record = $(this).attr("data-recordId");
        _.setState({
          qrcodeModal: true,
          currentMachineId: record,
          // queryUrl: `${process.env.REACT_APP_FEEDBACK_URL}?id=${record.record.id}`,
        })
      });

      $("body").on("click", ".locationBtn", function () {
        const record = $(this).attr("data-recordId");
        _.setState({
          assignLocationModalOpen: true,
          currentMachineId: record
        })
      });
      $("body").on("click", ".changeLocationBtn", function () {
        const record = $(this).attr("data-recordId");
        _.setState({
          changeLocationModal: true,
          currentMachineId: record
        })
      });
      $("body").on("click", ".viewStatusBtn", function () {
        const record = $(this).attr("data-recordId");
        _.setState({
          viewStatusModelOpen: true,
          currentMachineId: record,
        });
      });
    });
    $("body").on("change", ".togBtn", function () {
      let newStatus;
      if ($(this).is(":checked")) {
        newStatus = true;
      } else {
        newStatus = false;
      }
      const record = $(this).attr("data-recordId");
      _.machineStatus(record, newStatus);
      return false;
    });


    await this.getFilterRecords();
    this.renderDataTable([]);
  }

  async componentDidUpdate() {
    await this.handleMachineFilterSet()
   }

  getFilter = () => {
    let filter: any = {};
    if (this.state.filterLocation.length > 0) {
      filter["location_id"] = { in: this.state.filterLocation };
    }
    if (this.state.filterManager.length > 0) {
      filter["deploying_person_id"] = { in: this.state.filterManager };
    }
    if (this.state.filterBoard.length > 0) {
      filter["board_id"] = { in: this.state.filterBoard };
    }
    if (this.state.filterMacAddress) {
      filter["mac_address"] = { like: "%" + this.state.filterMacAddress + "%" };  
    }
    if (this.state.filterCupHolder.length > 0) {
      filter["cup_holder_id"] = { in: this.state.filterCupHolder };
    }

    if (this.state.filterBlender.length > 0) {
      filter["blender_id"] = { in: this.state.filterBlender };
    }
    if (this.state.filterMechanicalHardwareVersion) {
      filter["hardware_version"] = { eq: this.state.filterMechanicalHardwareVersion };
    }
    // {
    //   value: 'only',
    //   label: 'Only Shorter tubedoor',
    // },
    // {
    //   value: 'exclude',
    //   label: 'Without Shorter tubedoor',
    // },
    // {
    //   value: 'all',
    //   label: 'With Shorter tubedoor',
    // }
    if (this.state.filterReplace === 'only') {
      filter["replace_new_tube_doors_1mm_shorter"] = { eq: true };
    } else if (this.state.filterReplace === 'exclude') {
      filter["replace_new_tube_doors_1mm_shorter"] = { eq: false };
    }

    if (this.state.filterStatus === "true") {
      filter["is_active"] = { eq: true };
    } else if (this.state.filterStatus === "false") {
      filter["is_active"] = { eq: false };
    }
    if (this.state.filterStatus === "all") {
      delete filter["is_active"]
    }
    if (this.state.filterIsPolyfuses === "true") {
      filter["is_polyfuses"] = { eq: true };
    } else if (this.state.filterIsPolyfuses === "false") {
      filter["is_polyfuses"] = { eq: false };
    }
    if (this.state.filterIsPolyfuses === "all") {
      delete filter["is_polyfuses"]
    }
    if (this.state.filterName) {
      filter["name"] = { like: "%" + this.state.filterName + "%" };
    }
    if (this.state.filterSerialNumber) {
      filter["serial_number"] = { like: "%" + this.state.filterSerialNumber + "%" }
    }
    if (this.state.filterMachineStatus.length > 0) {
      filter["machine_status_id"] = { in: this.state.filterMachineStatus };
    }
    if (this.state.filterRetired) {
      switch (this.state.filterRetired) {
        case 'exclude':
          filter['is_retired'] = { eq: false }
          break;
        case 'only':
          filter['is_retired'] = { eq: true }
          break;
        case 'all':
          delete filter['is_retired'];
          break;
      }
    }

    if (this.state.applyDateFilterOn !== "") {

      let dateFilterAppliedOnLabel = "";
      switch (this.state.applyDateFilterOn) {
        case 'createdAt':
          dateFilterAppliedOnLabel = 'created_at';
          break;
        case 'deploymentDate':
          dateFilterAppliedOnLabel = 'deployment_date';
          break;
        case 'retiredAt':
          dateFilterAppliedOnLabel = 'retired_at';
          break;
      }

      if (this.state.filterFromDate !== "" || this.state.filterToDate !== "") {
        filter[dateFilterAppliedOnLabel] = {};
      }
      if (this.state.filterFromDate !== "" && this.state.filterToDate === "") {
        filter[dateFilterAppliedOnLabel]["gt"] = new Date(
          this.state.filterFromDate
        ).toISOString();
      } else if (
        this.state.filterFromDate === "" &&
        this.state.filterToDate !== ""
      ) {
        filter[dateFilterAppliedOnLabel]["lt"] = new Date(
          this.state.filterToDate
        ).toISOString();
      } else if (
        this.state.filterFromDate !== "" &&
        this.state.filterToDate !== ""
      ) {
        filter[dateFilterAppliedOnLabel]["between"] = [
          new Date(this.state.filterFromDate).toISOString(),
          new Date(this.state.filterToDate).toISOString(),
        ];
      }
    }
    return filter
  }
  getURL = (baseURL: string) => {

    const isApplyDateFilter = this.state.applyDateFilterOn;
    const isApplyFromDateFilter = this.state.filterFromDate;
    const isApplyToDateFilter = this.state.filterToDate;
    const isApplyNameFilter = this.state.filterName;
    const isApplySerialNumberFilter = this.state.filterSerialNumber;
    const isApplyLocationFilter = this.state.filterLocation;
    const isApplyBlenderFilter = this.state.filterBlender;
    const isApplyBoardFilter = this.state.filterBoard;
    const isApplyStatusFilter = this.state.filterStatus;
    const isApplyMechanicalHardwareVersionFilter = this.state.filterMechanicalHardwareVersion;
    const isApplyReplaceFilter = this.state.filterReplace;
    const isApplyCupHolderFilter = this.state.filterCupHolder;
    const isApplyMarketSegmentFilter = this.state.filterMarketSegement;
    const isApplyRetiredFilter = this.state.filterRetired;
    const isApplyRegionIdFilter = this.state.filterRegionId;
    const isApplyIsVipFilter = this.state.filterIsVip;
    const isApplyIsPolyfusesFilter = this.state.filterIsPolyfuses;
    const isApplyInStockFilter = this.state.filterInStock;
    const isApplyAddHouseClampsFilter = this.state.filterAddHouseClamps;
    const isApplyDeploymentManagerIdFilter = this.state.filterDeploymentManagerId;
    const isApplyMachineStatusFilter = this.state.filterMachineStatus;
    const isApplyMacAddressFilter = this.state.filterMacAddress;
   
    if (isApplyDateFilter) {
      baseURL += `&applyDate=${isApplyDateFilter}`
    }
    if (isApplyFromDateFilter) {
      let date = moment(isApplyFromDateFilter).format('YYYY-MM-DD HH:mm')
      let encodedDate = encodeURIComponent(date);
      baseURL += `&from_date=${encodedDate}`
    }
    if (isApplyToDateFilter) {
      let date = moment(isApplyToDateFilter).format('YYYY-MM-DD HH:mm')
      let encodedDate = encodeURIComponent(date);
      baseURL += `&to_date=${encodedDate}`
    }
    if (isApplyLocationFilter && isApplyLocationFilter.length > 0) {
      const locationIds = isApplyLocationFilter.join(',');
      baseURL += `&location_id=${locationIds}`
    }
    if (isApplyDeploymentManagerIdFilter && isApplyDeploymentManagerIdFilter.length > 0) {
      const deploymentMangerIds = isApplyDeploymentManagerIdFilter.join(',');
      baseURL += `&deploymentManagerId=${deploymentMangerIds}`
    }
    if (isApplyRegionIdFilter  && isApplyRegionIdFilter.length > 0) {
      const regionIds = isApplyRegionIdFilter.join(",");
      baseURL += `&regionId=${regionIds}`
    }
    if (isApplyIsVipFilter === "true" || isApplyIsVipFilter === "false" || isApplyIsVipFilter === 'all') {
      baseURL += `&is_vip=${isApplyIsVipFilter}`
    }
    if (isApplyIsPolyfusesFilter === "true" || isApplyIsPolyfusesFilter === "false" || isApplyIsPolyfusesFilter === 'all') {
      baseURL += `&is_polyfuses=${isApplyIsPolyfusesFilter}`
    }
    if (isApplyInStockFilter) {
      baseURL += `&inStock=${isApplyInStockFilter}`
    }
    if (isApplyAddHouseClampsFilter === "true" || isApplyAddHouseClampsFilter === "false" || isApplyAddHouseClampsFilter === 'all') {
      baseURL += `&addHouseClamps=${isApplyAddHouseClampsFilter}`
    }
    if (isApplyMarketSegmentFilter  && isApplyMarketSegmentFilter.length > 0) {
      const marketSegmentIds = isApplyMarketSegmentFilter.join(',');
      baseURL += `&marketSegmentId=${marketSegmentIds}`
    }
    if (isApplyBoardFilter) {
      const boardIds =isApplyBoardFilter.join(',');
      baseURL += `&board_id=${boardIds}`
    }
    if (isApplyCupHolderFilter) {
      const cupHolderIds =isApplyCupHolderFilter.join(',');
      baseURL += `&cup_holder_id=${cupHolderIds}`
    }
    if (isApplyBlenderFilter) {
      const blenderIds =isApplyBlenderFilter.join(',');
      baseURL += `&blender_id=${blenderIds}`
    }
    if (isApplyMechanicalHardwareVersionFilter) {
      baseURL += `&hardware_version=${isApplyMechanicalHardwareVersionFilter}`
    }
    if (isApplyNameFilter) {
      let decodedLocationName = decodeURIComponent(isApplyNameFilter); 
      let encodedLocationName = encodeURIComponent(decodedLocationName);
      baseURL += `&name=${encodedLocationName}`; 
    }
    if (isApplySerialNumberFilter) {
      let decodedLocationName = decodeURIComponent(isApplySerialNumberFilter); 
      let encodedLocationName = encodeURIComponent(decodedLocationName); 
      baseURL += `&serial_number=${encodedLocationName}`; 
    }
    if (isApplyStatusFilter === "true" || isApplyStatusFilter === "false" || isApplyStatusFilter === 'all') {
      baseURL += `&is_active=${isApplyStatusFilter}`;
    } 
    if (isApplyReplaceFilter || isApplyReplaceFilter === false || isApplyReplaceFilter === 'all') {
      baseURL += `&replace_new_tube_doors_1mm_shorter=${isApplyReplaceFilter}`;
    }
    if (isApplyRetiredFilter  || isApplyRetiredFilter ===false || isApplyRetiredFilter === 'all') {
      baseURL += `&is_retired=${isApplyRetiredFilter}`;
    }
    if (isApplyMachineStatusFilter) {
      const machineStatusIds =isApplyMachineStatusFilter.join(',');
      baseURL += `&machine_status_id=${machineStatusIds}`
    }
    if (isApplyMacAddressFilter) {
      let decodedMacAddress = decodeURIComponent(isApplyMacAddressFilter); 
      let encodedMacAddress = encodeURIComponent(decodedMacAddress);
      baseURL += `&mac_address=${encodedMacAddress}`; 
    }

    return baseURL;
   }
  renderDataTable = async (rows: any) => {
    var _this = this;
    const getPermissions: any = localStorage.getItem("permissions");
    const permissions = getPermissions || [];

    const filter = this.getFilter();
    await this.getColumnData();
    let inStock: any = false;
    if (this.state.filterInStock) {
      inStock = true;
    }
    const selectedMarketSegmentIds = this.state.filterMarketSegement ? this.state.filterMarketSegement : null;
    const filterAddHouseClamps = this.state.filterAddHouseClamps;
    const filterRegionId = this.state.filterRegionId ? this.state.filterRegionId : null;
    const filterIsVip = this.state.filterIsVip;
    const filterDeploymentManagerId = this.state.filterDeploymentManagerId ? this.state.filterDeploymentManagerId : null;

    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    let authToken = "";
    if (lsValue) {
      const user = JSON.parse(lsValue);
      authToken = user.access_token;
    }
    $(document).ready(function () {
      $("#machineTable").DataTable().destroy();

      // eslint-disable-next-line
      const table = $("#machineTable").DataTable({
        pagingType: "full_numbers",
        serverSide: true,
        pageLength: 50,
        paging: true,
        searching: false,
        data: rows,
        "ajax": {
          "url": process.env.REACT_APP_API_URL,
          "type": "POST",
          "contentType": "application/json",
          "beforeSend": function (xhr) {
            xhr.setRequestHeader('Authorization',
              "Bearer " + authToken);
          },
          "data": function (d: any) {
            var gql = ` query getMachines(
            $where: MachinesResolver_GetMachines_FilterInputType,
            $inStock:Boolean!,
            $paginate:PaginatorArgs,
            $extraFilter: MahineExtraFilterInputs
                 ) {
                  getMachines (where: $where ,inStock:$inStock,paginate: $paginate, extraFilter:  $extraFilter) {
                    data {
                      id
                      uuid
                      serialNumber
                      macAddress
                      name
                      deploymentDate
                      locationId
                      deployingPersonId
                      softwareVersion
                      hardwareVersion
                      connectionStatus
                      createdById
                      createdAt
                      updatedById
                      replaceNewTubeDoors1mmShorter
                      isPolyfuses
                      noteRelatedToMechanicalHardwareVersion
                      lastSanitizerReplacedAt
                      lastFilterReplacedAt
                      updatedAt
                      isActive
                      remark
                      lastHearbeatSyncAt
                      lastSanitizeTime
                      retiredAt
                      retiredById
                      isRetired
                      blenderId
                      boardId
                      machineStatusId
                      machineStatus{
                        id
                        name
                      }
                      cupHolderId
                      retiredBy {
                        id
                        firstName
                        lastName
                      }
                      updatedBy {
                        id
                        firstName
                        lastName
                      }
                      deployingPerson {
                        id
                        firstName
                        lastName
                      }
                      location {
                        id
                        customerId
                        customer{
                          id
                          name
                          customerCompanyName
                          isVip
                        }
                        name
                        address
                        regionId
                        contactName
                        contactPhone
                        marketSegmentId
                        deploymentManagerId
                        deploymentManager{
                          firstName
                        }
                        locationTypeId
                        targetLaunchDate
                        noOfBlenders
                        noOfFreezers
                        distributorId
                        samplingRecommendedTime
                        samplingRecommendedDays
                        createdById
                        createdAt
                        updatedById
                        updatedAt
                        isActive
                      }
                     blender {
                      id
                      versionNo
                      title
                    }
                    board {
                      id
                      versionNo
                      title
                    }
                    cupHolder {
                      id
                      title
                    }
                    }
                    totalPages
                    total
                    perPage
                    page
                  }

          }`;

            const currentPage = (d?.start) ? (d.start / d.length) + 1 : 1;
            const length = d?.length > 0 ? d.length : 50;
            // const sorting = d?.order?.[0]?.dir?.toUpperCase() || "ASC";
            // _this.setState({ sorting });
            var query = {
              "operationName": null,
              "query": gql,
              "variables": {
                "where": filter,
                inStock: inStock,
                paginate: {

                  "page": currentPage,
                  "per_page": length

                },
                extraFilter: {
                  marketSegmentId: selectedMarketSegmentIds,
                  addHouseClamps: filterAddHouseClamps,
                  regionId: filterRegionId,
                  deploymentManagerId: filterDeploymentManagerId,
                  is_vip: filterIsVip,
                }
              }
            };

            return JSON.stringify(query);
          },


          "dataSrc": function (json) {
            json.recordsTotal = json.data.getMachines.total;
            json.recordsFiltered = json.data.getMachines.total;

            const rows: any[] = [];
            const records = json.data.getMachines.data;
            // const currentPage = json.data.getMachines.page;
            // const recordsPerPage = json.data.getMachines.perPage;

            // const sorting = _this.state.sorting;

            // const sortedRecords = records.sort((a: any, b: any) => {
            //   const dateA = new Date(a.createdAt).getTime();
            //   const dateB = new Date(b.createdAt).getTime();
            //   return sorting === "ASC" ? dateA - dateB : dateB - dateA;
            // });

            // let counter: number;
            // if (sorting === "ASC") {
            //   counter = (currentPage - 1) * recordsPerPage + 1;
            // } else {
            //   counter = json.recordsTotal - (currentPage - 1) * recordsPerPage;
            // }

            // const recordsWithCounters = sortedRecords.map((record: any, index: number) => {
            //   const recordCounter = sorting === "ASC" ? counter + index : counter - index;
            //   return { ...record, counter: recordCounter };
            // });
        
            // _this.setState({ rows: recordsWithCounters });

            for (const record of records) {
              let deploymentDate = "";
              if (record.deploymentDate) {
                deploymentDate = Moment.unix(record.deploymentDate / 1000)
                  .local()
                  .format("YYYY-MM-DD");
              }
              let createdAt = "";
              if (record.createdAt) {
                createdAt = Moment.utc(record.createdAt)
                  .local()
                  .format("YYYY-MM-DD HH:mm:ss");
              }
              let retiredAt = ""
              if (record.retiredAt) {
                retiredAt = Moment.utc(record.retiredAt)
                  .local()
                  .format("YYYY-MM-DD HH:mm:ss");
              }
              let lastHearbeatSyncAt = ""
              if (record.lastHearbeatSyncAt) {
                lastHearbeatSyncAt = Moment.utc(record.lastHearbeatSyncAt)
                  .local()
                  .format("YYYY-MM-DD HH:mm:ss");
              }
              let lastSanitizeTime = ""
              if (record.lastSanitizeTime) {
                lastSanitizeTime = Moment.utc(record.lastSanitizeTime)
                  .local()
                  .format("YYYY-MM-DD HH:mm:ss");
              }
              const tempRecord = {
                id: record.uuid,
                customerName: record.location?.customer?.name ?? '',
                is_vip: record.location?.customer?.isVip === true ? 'Yes' : 'No',
                macAddress: record.macAddress ?? "",
                customerCompanyName: record.location?.customer?.customerCompanyName ?? "",
                locationId: record.locationId ?? "",
                locationName: record.location?.name ?? "",
                locationAddress: record.location?.address ?? "",
                serialNumber: record.serialNumber,
                machineName: record.name,
                lastSanitizerReplacedAt: record.lastSanitizerReplacedAt ? Moment.utc(record.lastSanitizerReplacedAt)
                  .local()
                  .format("YYYY-MM-DD HH:mm:ss") : "",
                lastFilterReplacedAt: record.lastFilterReplacedAt ? Moment.utc(record.lastFilterReplacedAt)
                  .local()
                  .format("YYYY-MM-DD HH:mm:ss") : "",
                lastHearbeatSyncAt,
                lastSanitizeTime,
                deploymentDate: deploymentDate,
                deploymentManager: record.location?.deploymentManager?.firstName ?? "",
                deployedBy: record.deployingPerson?.firstName ?? "",
                remark: record.remark ?? "",
                createdAt,
                retiredAt,
                retiredBy: record?.retiredBy?.firstName ?? "",
                blender: record?.blender?.title ?? "",
                machineStatus: record?.machineStatus?.name ?? "",
                board: record?.board?.title ?? "",
                cupHolder:record?.cupHolder?.title ?? "",
                isActive: record.isActive,
                connectionStatus: _.capitalize(_.lowerCase(record.connectionStatus)),
                softwareVersion: record.softwareVersion ?? 0,
                hardwareVersion: record.hardwareVersion ?? 0,
                isPolyfuses: record.isPolyfuses,
                recordId: record.id,
                record: record,
              };
              rows.push(tempRecord);
            }
            return rows;
          },
        },
        "initComplete": function (settings, json) {
          table.page(Number(_this.state.page)).draw(false);

        },
        columns: [
          {
            name: "index",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            render: function (data, type, row) {
              let json = JSON.stringify(row);
              let jsonString = json.replaceAll("'","");
              let returnsData =
                '<div data-bs-toggle="tooltip" title="Click here to view machine"><a class="clickable_entry" data-value=\'' + jsonString + '\' href="/machines/list/history/' +
                 row.recordId + '?page=' + _this.state.page +_this.state.finalUrl +
                '"> ' +
                row.id +
                "</div></a>";
              return returnsData;
            },
          },
          {
            name: "locationName",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            render: function (data, type, row) {
              let returnsData = ""
              if (row.is_vip === 'Yes') {
                returnsData +=
                '<div data-bs-toggle="tooltip" title="Click here to view location"><a href="/locations/list/history/' +
                row.locationId + '?page=' + _this.state.page +_this.state.finalUrl +
                '"> <i class="fa fa-crown text-warning me-1"></i>' +
                row.locationName +
                "</a></div>";
              }else{
                returnsData +=
                '<div data-bs-toggle="tooltip" title="Click here to view location"><a href="/locations/list/history/' +
                row.locationId + '?page=' + _this.state.page +_this.state.finalUrl +
                '"> ' +
                row.locationName +
                "</div></a>";
              }
               
              return returnsData;
            },
          },
          {
            name: "macAddress",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            render: function (data, type, row) {
              let returnsData =
                '<div data-bs-toggle="tooltip" title="Click here to view machine"><a  href="/machines/list/history/' +
                row.recordId + '?page=' + _this.state.page +_this.state.finalUrl +
                '"> ' +
                row.macAddress +
                "</div></a>";
              return returnsData;
            },
          },
          {
            name: "serialNumber",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            render: function (data, type, row) {
              let returnsData =
                '<div data-bs-toggle="tooltip" title="Click here to view machine"><a href="/machines/list/history/' +
                row.recordId + '?page=' + _this.state.page +_this.state.finalUrl +
                '"> ' +
                row.serialNumber +
                "</div></a>";
              return returnsData;
            },
          },

          {
            name: "machineName",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            render: function (data, type, row) {
              let returnsData =
                '<div data-bs-toggle="tooltip" title="Click here to view machine"><a  href="/machines/list/history/' +
                row.recordId + '?page=' + _this.state.page +_this.state.finalUrl +
                '"> ' +
                row.machineName +
                "</div></a>";
              return returnsData;
            },
          },
          {
            name: "deploymentDate",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            render: function (data, type, row) {
              return row.deploymentDate;
            },
          },
          {
            name: "deployedBy",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            render: function (data, type, row) {
              return row.deployedBy;
            },
          },
          {
            name: "machineStatus",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            render: function (data, type, row) {
              return row.machineStatus;
            },
          },
          {
            name: "remark",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            render: function (data, type, row) {
              return row.remark;
            },
          },
          {
            name: "softwareVersion",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            render: function (data, type, row) {
              return row.softwareVersion;
            },
          },
          {
            name: "hardwareVersion",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            render: function (data, type, row) {
              return row.hardwareVersion;
            },
          },
          {
            name: "replaceNewTubeDoors1mmShorter",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            render: function (data, type, row) {
              return row.replaceNewTubeDoors1mmShorter ? 'Yes' : 'No';
            },
          },
          {
            name: "isPolyfuses",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            render: function (data, type, row) {
              return row?.isPolyfuses ? 'Yes' : 'No';
            },
          },
          {
            name: "createdAt",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            render: function (data, type, row) {
              return row.createdAt;
            },
          },
          {
            name: "retiredAt",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            render: function (data, type, row) {
              return row.retiredAt;
            },
          },
          {
            name: "retiredBy",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            render: function (data, type, row) {
              return row.retiredBy;
            },
          },
          {
            name: "blender",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            render: function (data, type, row) {
              return row.blender;
            },
          },
          {
            name: "board",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            render: function (data, type, row) {
              return row.board;
            },
          },
          {
            name: "cupHolder",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            render: function (data, type, row) {
              return row.cupHolder;
            },
          },
          {
            name: "lastSanitizeTime",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            render: function (data, type, row) {
              return row.lastSanitizeTime;
            },
          },
          {
            name: "lastHearbeatSyncAt",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            render: function (data, type, row) {
              return row.lastHearbeatSyncAt;
            },
          },
          {
            name: "lastSanitizerReplacedAt",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            render: function (data, type, row) {
              return row.lastSanitizerReplacedAt;
            },
          },
          {
            name: "lastFilterReplacedAt",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('lastFilterReplacedAt'),
            render: function (data, type, row) {
              return row.lastFilterReplacedAt;
            },
          },
          {
            name: "connectionStatus",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            render: function (data, type, row) {
              return row.connectionStatus;
            },
          },
          {
            name: "isVip",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('isVip'),
            render: function (data, type, row) {
              return row.is_vip;
            },
          },
          {
            name: "isActive",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            render: function (data, type, row) {
              let returnData = "";
              if (permissions?.includes("update-machine") === true) {
                let isChecked =
                  row.isActive === true
                    ? 'checked="checked"'
                    : row.isActive === false;
                returnData +=
                  '<label class="status-switch"><input type="checkbox" ' +
                  isChecked +
                  "  data-recordId='" +
                  row.recordId +
                  '\' class="togBtn"><div class="status-slider round"></div></label>';
              } else {
                if (row.isActive === true) {
                  returnData += '<div class="text-dark"> Active</div>';
                } else {
                  returnData += '<div class="text-dark"> Inactive</div>';
                }
              }
              return returnData;
            },
          },
          {
            name: "action",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            render: function (data, type, row) {
              let returnsData = "";
              if (permissions?.includes("update-machine") === true) {
                returnsData +=
                  '<div data-bs-toggle="tooltip" title="Click here to edit machine"><button class="btn btn-secondary btn editBtn p-2 px-4 m-1" data-recordId=\'' +
                  row.recordId +
                  "'><i class='fa fa-pencil' style='font-size:1rem;'></i></button></div>";
              }
              returnsData +=
                '<div data-bs-toggle="tooltip" title="Click here to view machine"><a class="btn btn-primary btn p-2 px-4 m-1" href="/machines/list/history/' +
                row.recordId + '?page=' + _this.state.page +_this.state.finalUrl +
                '"><i class="fa fa-eye"></i></a></div>';
              returnsData +=
                '<div data-bs-toggle="tooltip" title="Click here to add feedback"><button class="btn btn-secondary btn qrCodeBtn p-2 px-4 m-1" data-recordId=\'' +
                row.recordId +
                "'><i class='fa fa-qrcode'></i></button></div>";
              if (row.locationId === "") {
                returnsData +=
                  '<div data-bs-toggle="tooltip" title="Click here to assign location"><button class="btn btn-secondary btn locationBtn p-2 px-4 m-1" data-recordId=\'' +
                  row.recordId +
                  "'><i class='fa-solid fa-location-dot'></i></button></div>";
              }
              if (row.locationId !== "") {
                returnsData +=
                  '<div data-bs-toggle="tooltip" title="Click here to change location"><button class="btn btn-secondary btn changeLocationBtn p-2 px-4 m-1" data-recordId=\'' +
                  row.recordId +
                  "'><i class='fa fa-exchange' ></i></i></button></div>";
              }
              if (row.isActive !== "") {
                returnsData +=
                  '<div data-bs-toggle="tooltip" title="Click here to view status"><button class="btn btn-secondary btn viewStatusBtn p-2 px-4 m-1" data-recordId=\'' +
                  row.recordId +
                  "'><i class='fa fa-display' ></i></i></button></div>";
              }
              return returnsData;
            },
          },
        ],
        processing: true,
        dom: "Bfrtip",
        order: [[0, "desc"]],
        lengthMenu: [
          [10, 20, 30, 50, -1],
          [10, 20, 30, 50, "All"],
        ],
        columnDefs: [
          {
            targets: 0,
            render: function (data, type, row, meta) {
              return type === "export" ? meta.row + 1 : data;
            },
          },
        ],
        createdRow: function (row, data, rowIndex) {
          var title: any = [];
          $("#machineTable thead tr th").each(function () {
            title.push($(this).text());
          });
          $.each($("td", row), function (colIndex) {
            $(this).attr("data-title", title[colIndex]);
          });
        },
      });
      table.on('page', function () {
        const currentPage = table.page.info();

        _this.setState({
          page: currentPage.page
        })

      });
      $(document)[0].oncontextmenu = function () { return false; }

      $('#machineTable tbody').mousedown(function (e) {
        if (e.buttons === 1) return true;
        e.preventDefault();
        var top = e.clientY;
        var left = e.clientX;
        const recordString = $(e.target).parents('tr').find('a.clickable_entry').attr('data-value');
        let record;
        if (recordString) {
          record = JSON.parse(recordString)
        }
        if (record) {
          if (record.record.locationId !== null) {
            $('#changelocation_record_link').show().attr('data-recordId', record.recordId)
          }
          else {
            $('#changelocation_record_link').hide().attr(
              'data-recordId', ''
            )
          }
          if (record.record.locationId == null) {
            $('#assignlocation_record_link').show().attr('data-recordId', record.recordId)
          }
          else {
            $('#assignlocation_record_link').hide().attr(
              'data-recordId', ''
            )
          }
          $('#view_record_link').attr('href', `/machines/list/history/${record.recordId}?page=${_this.state.page}${_this.state.finalUrl}`)
          $('#qrcode_record_link').attr('data-recordId', record.recordId)
          $("#viewStatus_record_link").attr("data-recordId", record.recordId);
          $('#edit_record_link').attr('data-recordId', record.recordId)
          $('#createTicket_record_link').attr('href', `/tickets/create?machine_id=${record.recordId}&label=${record?.record?.location?.name ?? ""} (${record?.record?.serialNumber ?? ""})`)
          $("#context-menu").css({
            top: top,
            left: left
          }).show();
        }
        return false;
      })
      $('html').click(function () {
        $("#context-menu").hide();
      })
      _this.displaySelectedColumns(table);
    });
    $(".dt-buttons").remove();
  };

  async getFilterRecords() {
    const locationRecord = await getLocationsForFilter();
    const locationResults: any = locationRecord.data ? locationRecord.data : [];
    locationResults.sort(function (a: any, b: any) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    const locationRegionRecord = await getRegion();
    const locationRegionResults: any = locationRegionRecord
      ? locationRegionRecord
      : [];
    locationRegionResults.sort(function (a: any, b: any) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    const machinesRecords = await getMachinesNameAndSerials();
    const machinesResults: any = machinesRecords.data ? machinesRecords.data : [];
    machinesResults.sort(function (a: any, b: any) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    const hardwareVersionsList = await getHardwareVersionsList();

    const deploymentManagerRecord = await getUsers();
    const deploymentManagerResults: any = deploymentManagerRecord
      ? deploymentManagerRecord
      : [];
    deploymentManagerResults.sort(function (a: any, b: any) {
      if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
        return -1;
      }
      if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    const blenderRecord = await getBlendersList();
    const blenderResults: any = blenderRecord
      ? blenderRecord
      : [];

    blenderResults.sort(function (a: any, b: any) {
      if (a.title.toLowerCase() < b.title.toLowerCase()) {
        return -1;
      }
      if (a.title.toLowerCase() > b.title.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    const boardRecord = await getBoardsList();
    const boardResults: any = boardRecord
      ? boardRecord
      : [];


    boardResults.sort(function (a: any, b: any) {
      if (a.title.toLowerCase() < b.title.toLowerCase()) {
        return -1;
      }
      if (a.title.toLowerCase() > b.title.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    const marketSegmentRecord = await getMarketSegment();
    const marketSegmentResults: any = marketSegmentRecord
      ? marketSegmentRecord
      : [];
    marketSegmentResults.sort(function (a: any, b: any) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    const cupHolderRecord = await getCupHoldersList();
    const cupHolderResults: any = cupHolderRecord
      ? cupHolderRecord
      : [];


    cupHolderResults.sort(function (a: any, b: any) {
      if (a.title.toLowerCase() < b.title.toLowerCase()) {
        return -1;
      }
      if (a.title.toLowerCase() > b.title.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    const machineStatusRecord = await getMachineStatusList();
    this.setState({
      blenderDB: blenderResults,
      boardDB: boardResults,
      cupHolderDB:cupHolderResults,
      locationDB: locationResults,
      locationRegionDB: locationRegionResults,
      managerDB: deploymentManagerResults,
      machinesDB: machinesResults,
      marketSegmentDB: marketSegmentResults,
      machineStatusDB: machineStatusRecord,
      hardwareVersionsList: [...hardwareVersionsList.sort(function (a: any, b: any) { return parseFloat(a) - parseFloat(b) }), 'missing']
    });
  }


  handleDownloadExcel = async () => {
    this.setState({
      downloadLoading: true
    })
    const filter = this.getFilter();
    const selectedMarketSegmentIds = this.state.filterMarketSegement;
    let inStock: any = false;
    if (this.state.filterInStock) {
      inStock = true;
    }

    const filterAddHouseClamps = this.state.filterAddHouseClamps;
    const filterRegionId = this.state.filterRegionId;
    const filterIsVip = this.state.filterIsVip;
    const filterDeploymentManagerId = this.state.filterDeploymentManagerId;
    const extraFilter = {
      marketSegmentId: selectedMarketSegmentIds,
      addHouseClamps: filterAddHouseClamps,
      regionId: filterRegionId,
      deploymentManagerId: filterDeploymentManagerId,
      is_vip: filterIsVip,
    }
    const result: any = await getMachines(filter, inStock, undefined, extraFilter); 
    const excelRecords = [];
    for (const record of result.data) {
      const tempRecord: any = {
        id: record.uuid,
        'Customer name': record.location?.customer?.name ?? '',
        'Customer company name': record.location?.customer?.customerCompanyName ?? "",
        'Location name': record.location?.name ?? "",
        'Location address': record.location?.address ?? "",
        'Serial number': record.serialNumber,
        'Mac address': record.macAddress,
        'Machine name': record.name,
        'Last heartbeat Sync at': record.lastHearbeatSyncAt ? Moment.utc(record.lastHearbeatSyncAt)
          .local()
          .format("YYYY-MM-DD HH:mm:ss") : "",
        'Last sanitizer replaced at': record.lastSanitizerReplacedAt ? Moment.utc(record.lastSanitizerReplacedAt)
          .local()
          .format("YYYY-MM-DD HH:mm:ss") : "",
        'Last sanitize at': record.lastSanitizeTime ? Moment.utc(record.lastSanitizeTime)
          .local()
          .format("YYYY-MM-DD HH:mm:ss") : "",
        'Last Filter Replaced At': record.lastFilterReplacedAt ? Moment.utc(record.lastFilterReplacedAt)
          .local()
          .format("YYYY-MM-DD HH:mm:ss") : "",
        'Deployed at': record.deploymentDate ? Moment.unix(record.deploymentDate / 1000)
          .local()
          .format("YYYY-MM-DD") : "",
        'Deployment manager': record.location?.deploymentManager?.firstName ?? "",
        'Deployed by': record.deployingPerson?.firstName ?? "",
        'Machine Status': _.capitalize(
          _.lowerCase(record?.machineStatus?.name ?? "")
        ),
        'Remark': record.remark ?? "",
        'Blender': record?.blender?.title ?? "",
        'Board': record?.board?.title ?? "",
        'Cup holder': record?.cupHolder?.title ?? "",
        'Is Vip': record.location?.customer?.isVip === true ? 'Yes' : 'No',
        'Site Status': record.isActive === true ? "Yes" : "No",
        'IS pilot?': record.isPilot === true ? "Yes" : "No",
        'Trial end date': record.isPilot === true ? record.trialEndDate : "",
        'Connection status': _.capitalize(_.lowerCase(record.connectionStatus)),
        'Software version': record.softwareVersion ?? 0,
        'Mechanical hardware version': record.hardwareVersion ?? 0,
        'Replace new tube doors -1mm shorter': record.replaceNewTubeDoors1mmShorter ? 'Yes' : 'No',
        'Is Polyfuses?': record?.isPolyfuses ? 'Yes' : 'No',
        'Created at': Moment.utc(record.createdAt)
          .local()
          .format("YYYY-MM-DD HH:mm:ss"),
        'Retired at': record.retiredAt ? Moment.utc(record.retiredAt)
          .local()
          .format("YYYY-MM-DD HH:mm:ss") : "",
        'Retired By': record?.retiredBy?.firstName ?? "",
      };

      if (record?.metas?.length > 0) {
        for (let meta of record.metas) {
          const keyName = _.capitalize(_.toLower(meta.metaKey.replaceAll('_', ' ').trim()));
          tempRecord[keyName] = meta.metaValue;
        }
      }
      excelRecords.push(tempRecord);
    }
    this.setState({
      downloadLoading: false
    })
    DownloadExcel(excelRecords, "SHEET_NAME", "Machines_list");
  }

  machineStatus = async (recordId: any, newStatus: boolean) => {
    try {
      await updateMachineStatus(recordId, newStatus);
    } catch (e: any) {
      console.log(e);
    }
  };


  handleCloseMachine = () => {
    this.setState({
      machineEditModal: false,
      currentMachineId: ""

    });
  };
  handleCloseChangeLocation = () => {
    this.setState({
      changeLocationModal: false,
      currentMachineId: ""
    })
  }
  handleCloseAssignLocation = () => {
    this.setState({
      assignLocationModalOpen: false,
      currentMachineId: ""
    })
  }
  handleCloseViewStatus = () => {
    this.setState({
      viewStatusModelOpen: false,
      currentMachineId: "",
    });
  };

  handleCloseQrcode = () => {
    this.setState({
      qrcodeModal: false,
      currentMachineId: ""
    })
  }
  refreshTable = async () => {
    await this.getFilterRecords();
    this.renderDataTable([]);
  }

  searchData = async () => {
    this.setState({
      addLoading: true,
    });
    const url = this.getURL(`/machines/list?page=${this.state.page}`);
    window.history.replaceState({}, '', url);
    this.setState({
      finalUrl: this.getURL('')
    });
    this.renderDataTable([]);
    this.setState({
      addLoading: false,
    });
    return false;
  };

  removeSearch = async () => {
    this.setState({
      filterLoading: true,
    });
    this.state.resetStatus.current.setValue("");
    this.state.resetAddHouseClamps.current.setValue("");
    this.state.resetMechanicalHardwareVersion.current.setValue("");
    this.state.resetIsVip.current.setValue("");
    this.state.resetIsPolyfuses.current.setValue("");
    // eslint-disable-next-line
    this.state.resetFrom.current.state.inputValue = "";
    // eslint-disable-next-line
    this.state.resetTo.current.state.inputValue = "";
    this.setState({
      filterToDate: "",
      filterFromDate: "",
      filterStatus: "",
      filterName: "",
      filterMacAddress:"",
      filterSerialNumber: "",
      filterMechanicalHardwareVersion: "",
      filterLocation: "",
      filterManager: "",
      filterBoard: "",
      filterCupHolder:"",
      filterBlender: "",
      filterInStock: "",
      filterMachineStatus:"",
      filterIsVip: "",
      filterIsPolyfuses: "",
      filterAddHouseClamps: "",
      filterRegionId: [],
      filterDeploymentManagerId: [],
      filterMarketSegement: [],
      filterReplace: null,
      inStock: false,
      locationSelected: null,
      managerSelected: null,
      blenderSelected: null,
      boardSelected: null,
      cupHolderSelected:null,
      locationRegionSelected: null,
      deploymentManagerSelected: null,
      marketSegmentIdSelected: [],
      machineStatusSelected: null,
    });
    window.history.replaceState({}, '', "/machines/list");
    await this.getFilterRecords();
    this.renderDataTable([]);
    this.setState({
      filterLoading: false,
    });
    return false;
  };
  // async getColumnData() {
  getColumnData = async () => {
    var _ = this;
    const data = await getModuleDataDisplaySettingByKey(this.state.moduleKeyName);
    if (data) {
      _.setState({ selectedColumns: data.metaValue })
      return data.metaValue;
    }
    return [];
  }

  displaySelectedColumns = async (table: any) => {
    var _this = this;
    if (_this.state.selectedColumns.length > 0) {
      table.settings().init().columns?.map(function (val: any, key: any) {
        if (!_this.state.selectedColumns.includes(val.name)) {
          table.column(key).visible(false);
        }
        return false;
      });
    }
    $('#machineTable').show();
    return false;
  };

  handleColumnDataSelect = async (e: any) => {
    const selCol = (e.target.checked)
      ? [...this.state.selectedColumns, e.target.value]
      : this.state.selectedColumns.filter((item: string) => item !== e.target.value);
    this.setState({ selectedColumns: selCol });
  };

  handleColumnDataSubmit = async (e: any) => {
    const selectedColumns = this.state.selectedColumns;
    await updateModuleDataDisplaySettingByKey(this.state.moduleKeyName, selectedColumns);
    this.refreshTable();
  };
  handleSelectAll = () => {
    const { selectedColumns, columnData } = this.state;
    const allKeys = columnData.map((col: any) => col.key);
    if (selectedColumns.length === columnData.length) {
        this.setState({ selectedColumns: [] });
    } else {
        this.setState({ selectedColumns: allKeys });
    }
};
  getLabelForStatus(status:any) {
    if (status === "true") {
      return "Active";
    } else if (status === "false") {
      return "Inactive";
    } else if (status === "all") {
      return "All";
    }else {
      return "Active"; 
    }
  }
  getLabelForRetired(retired:any) {
    if (retired === "only") {
      return "Only Retired";
    } else if (retired === "exclude") {
      return "Without Retired";
    } else if (retired === "all") {
      return "All";
    }else {
      return "With Retired"; 
    }
  }
  getLabelForReplace(retired:any) {
    if (retired === "only") {
      return "Only Shorter tubedoor";
    } else if (retired === "exclude") {
      return "Without Shorter tubedoor";
    } else if (retired === "all") {
      return "All";
    }else {
      return "With Shorter tubedoor"; 
    }
  }
  getLabelForHasHouseClamp(clamp:any) {
    if (clamp === "true") {
      return "Yes";
    } else if (clamp === "false") {
      return "No";
    } 
  }
  getLabelForIsVip(isVip:any) {
    if (isVip === "true") {
      return "Yes";
    } else if (isVip === "false") {
      return "No";
    } else if (isVip === "all") {
      return "All";
    }else {
      return "All"; 
    }
  }
 
  handleMachineFilterSet = async () => {
    if(this.getURL('')){
      if (this.state.filterDeploymentManagerId) {
        const selectManager = this.state.managerDB.filter((data: any) => this.state.filterDeploymentManagerId.includes(data.id))
       
        const finalSelectedManagerResponse = selectManager.map((x: any) => {
          return {
            value: x.id,
            label: x.firstName
          }
        })
         if (JSON.stringify(finalSelectedManagerResponse) !== JSON.stringify(this.state.deploymentManagerSelected)) {
          this.setState({
            deploymentManagerSelected: finalSelectedManagerResponse,
          });
        }
      }
      if (this.state.filterLocation) {
        const selectLocation = this.state.locationDB.filter((data: any) => this.state.filterLocation.includes(data.id))
        const finalSelectedLocationResponse = selectLocation.map((x: any) => {
          
          return {
            value: x.id,
            label: x.name
          }
        })
         if (JSON.stringify(finalSelectedLocationResponse) !== JSON.stringify(this.state.locationSelected)) {
          this.setState({
            locationSelected: finalSelectedLocationResponse,
          });
        }
      }
      if (this.state.filterRegionId) {
        const selectRegionId = this.state.locationRegionDB.filter((data: any) => this.state.filterRegionId.includes(data.id))
        const finalSelectedRegionIdResponse = selectRegionId.map((x: any) => {
          
          return {
            value: x.id,
            label: x.name
          }
        })
         if (JSON.stringify(finalSelectedRegionIdResponse) !== JSON.stringify(this.state.locationRegionSelected)) {
          this.setState({
            locationRegionSelected: finalSelectedRegionIdResponse,
          });
        }
      } 
      if (this.state.filterMarketSegement) {
        const selectMarketSegment = this.state.marketSegmentDB.filter((data: any) => this.state.filterMarketSegement.includes(data.id))
        const finalSelectedMarketSegmentResponse = selectMarketSegment.map((x: any) => {
          
          return {
            value: x.id,
            label: x.name
          }
        })
         if (JSON.stringify(finalSelectedMarketSegmentResponse) !== JSON.stringify(this.state.marketSegmentIdSelected)) {
          this.setState({
            marketSegmentIdSelected: finalSelectedMarketSegmentResponse,
          });
        }
      }
      if (this.state.filterBlender) {
        const selectBlender = this.state.blenderDB.filter((data: any) => this.state.filterBlender.includes(data.id))
        const finalSelectedBlenderResponse = selectBlender.map((x: any) => {
        
          return {
            value: x.id,
            label: x.title
          }
        })
         if (JSON.stringify(finalSelectedBlenderResponse) !== JSON.stringify(this.state.blenderSelected)) {
          this.setState({
            blenderSelected: finalSelectedBlenderResponse,
          });
        }
      }
      if (this.state.filterBoard) {
        const selectBoard = this.state.boardDB.filter((data: any) => this.state.filterBoard.includes(data.id))
        const finalSelectedBoardResponse = selectBoard.map((x: any) => {
          
          return {
            value: x.id,
            label: x.title
          }
        })
         if (JSON.stringify(finalSelectedBoardResponse) !== JSON.stringify(this.state.boardSelected)) {
          this.setState({
            boardSelected: finalSelectedBoardResponse,
          });
        }
      }
      if (this.state.filterCupHolder) {
        const selectCupHolder = this.state.cupHolderDB.filter((data: any) => this.state.filterCupHolder.includes(data.id))
        const finalSelectedCupHolderResponse = selectCupHolder.map((x: any) => {
          
          return {
            value: x.id,
            label: x.title
          }
        })
         if (JSON.stringify(finalSelectedCupHolderResponse) !== JSON.stringify(this.state.cupHolderSelected)) {
          this.setState({
            cupHolderSelected: finalSelectedCupHolderResponse,
          });
        }
      }
      if (this.state.filterMechanicalHardwareVersion) {
        const selectMechanicalHardwareVersion = this.state.hardwareVersionsList.filter((data: any) => this.state.filterMechanicalHardwareVersion.includes(data.id))
        const finalSelectedMechanicalHardwareVersionResponse = selectMechanicalHardwareVersion.map((x: any) => {
          
          return {
            value: x.value,
            label: x.value
          }
        })
         if (JSON.stringify(finalSelectedMechanicalHardwareVersionResponse) !== JSON.stringify(this.state.hardwareVersionSelected)) {
          this.setState({
            hardwareVersionSelected: finalSelectedMechanicalHardwareVersionResponse,
          });
        }
      }
      if (this.state.filterMachineStatus) {
        const selectMachineStatus = this.state.machineStatusDB.filter((data: any) => this.state.filterMachineStatus.includes(data.id))
        const finalSelectedMachineStatusResponse = selectMachineStatus.map((x: any) => {
        
          return {
            value: x.id,
            label: x.name
          }
        })
         if (JSON.stringify(finalSelectedMachineStatusResponse) !== JSON.stringify(this.state.machineStatusSelected)) {
          this.setState({
            machineStatusSelected: finalSelectedMachineStatusResponse,
          });
        }
      }
    }
  }

  render() {
    return (
      <>
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                Machines List
              </h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
              <button
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#machine-filter"
                aria-expanded="false"
                aria-controls="machine-filter"
                className="btn btn-bg-light collapsed fw-bold m-0 p-0 px-5 py-3 align-items-center card-title text-center">
                <KTSVG
                  path="/media/icons/duotune/general/gen031.svg"
                  className="svg-icon-4 me-1"
                />
                Filter
              </button>
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted text-hover-primary fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-dark fs-5">
                  Machines List
                </li>
              </ul>
            </div>
          </div>
        </div>


        <div className="collapse" id="machine-filter" aria-labelledby="machine-filter">
          <div className="card mt-9">
            <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              <div className="row mt-6">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Apply Date filter on</label>
                  <br />
                  <label
                    className="form-check form-check-sm form-check-custom form-check-solid align-items-center fs-5 mb-1 ms-2 "
                    key="apply_date_1"
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="applyDateFilterOn"
                      value='createdAt'
                      checked={this.state.applyDateFilterOn === 'createdAt'}
                      onChange={() =>
                        this.setState({
                          applyDateFilterOn: 'createdAt'
                        })
                      }
                    />
                    <span className="form-check-label fs-5 mb-0 ms-2">
                      Created At
                    </span>
                  </label>
                  <label
                    className="form-check form-check-sm form-check-custom form-check-solid align-items-center fs-5  mb-1 ms-2"
                    key="apply_date_2"
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="applyDateFilterOn"
                      value='deploymentDate'
                      checked={this.state.applyDateFilterOn === 'deploymentDate'}
                      onChange={() =>
                        this.setState({
                          applyDateFilterOn: 'deploymentDate'
                        })
                      }
                    />
                    <span className="form-check-label fs-5 mb-0 ms-2">
                      Deployment Date
                    </span>
                  </label>
                  <label
                    className="form-check form-check-sm form-check-custom form-check-solid align-items-center fs-5  mb-1 ms-2"
                    key="apply_date_3"
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="applyDateFilterOn"
                      value='retiredAt'
                      checked={this.state.applyDateFilterOn === 'retiredAt'}
                      onChange={() =>
                        this.setState({
                          applyDateFilterOn: 'retiredAt'
                        })
                      }
                    />
                    <span className="form-check-label fs-5 mb-0 ms-2">
                      Retired At
                    </span>
                  </label>
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">From Date</label>
                  <Datetime
                    ref={this.state.resetFrom}
                    closeOnSelect
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm"
                    value={this.state.filterFromDate}
                    onChange={(e: any) => {
                      const dt = e["_d"];
                      this.setState({
                        filterFromDate: dt,
                      });
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">To Date</label>
                  <Datetime
                    ref={this.state.resetTo}
                    closeOnSelect
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm"
                    value={this.state.filterToDate}
                    onChange={(e: any) => {
                      const dt = e["_d"];
                      this.setState({
                        filterToDate: dt,
                      });
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Deployment Manager
                  </label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        deploymentManagerSelected: selected,
                        filterDeploymentManagerId: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.deploymentManagerSelected}
                    options={Array.from(this.state.managerDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.firstName,
                        };
                      }
                    )}
                  />
                </div>
              </div>

              <div className="row align-items-center">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Machine Name</label>
                  <ReactSearchAutocomplete
                    items={this.state.machinesDB.map((machine: any) => ({ name: machine.name, id: machine.name }))}
                    onSelect={(item: any) => {
                      this.setState({
                        filterName: item.name,
                      });
                    }}
                    inputSearchString={this.state.filterName }
                    autoFocus
                    styling={{
                      borderRadius: '5px',
                      zIndex: 1
                    }}
                    fuseOptions={{
                      threshold: 0.2,
                    }}
                    onClear={() => {
                      this.setState({
                        filterName: '',
                      });
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Serial Number</label>

                  <ReactSearchAutocomplete
                    items={this.state.machinesDB.map((machine: any) => ({ name: machine.serialNumber, id: machine.serialNumber }))}
                    onSelect={(item: any) => {
                      this.setState({
                        filterSerialNumber: item.name,
                      });
                    }}
                    inputSearchString={this.state.filterSerialNumber }
                    autoFocus
                    styling={{
                      borderRadius: '5px',
                      zIndex: 1
                    }}
                    fuseOptions={{
                      threshold: 0.2,
                    }}
                    onClear={() => {
                      this.setState({
                        filterSerialNumber: '',
                      });
                    }}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Locations</label>
                  <ReactSelect
                    className="react-select"
                    placeholder=""
                    classNamePrefix="my-react-select"
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        locationSelected: selected,
                        filterLocation: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.locationSelected}
                    options={Array.from(this.state.locationDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Region</label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        locationRegionSelected: selected,
                        filterRegionId: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.locationRegionSelected}
                    options={Array.from(this.state.locationRegionDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />
                </div>

                {/* <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                <label className="form-label fs-4 fw-bold">
                  Person Deploying
                </label>
                <ReactSelect
                  className="react-select"
                  classNamePrefix="my-react-select"
                  isMulti
                  placeholder=""
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={(selected: any) => {
                    const manager = selected?.map((e: any) => e.value);
                    this.setState({

                      managerSelected: selected,
                      filterManager: manager,
                    });
                  }}
                  components={{
                    Option,
                  }}
                  value={this.state.managerSelected}
                  options={Array.from(this.state.managerDB).map(
                    (value: any) => {
                      return {
                        value: value.id,
                        label: value.firstName,
                      };
                    }
                  )}
                />
              </div> */}



              </div>

              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <label className="form-label fs-4  fw-bold">
                    Market Segment
                  </label>

                  <MultiSelect
                    class={clsx("react-select")}
                    title=""
                    setSelected={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);

                      this.setState({

                        marketSegmentIdSelected: selected,
                        filterMarketSegement: value,
                      });
                    }}
                    selected={this.state.marketSegmentIdSelected}
                    options={Array.from(this.state.marketSegmentDB).map((value: any) => {
                      return {
                        value: value.id,
                        label: value.name,
                      };
                    })}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Machine Status
                  </label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    isMulti
                    placeholder=""
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const machineStatus = selected?.map((e: any) => e.value);
                      this.setState({
                        machineStatusSelected: selected,
                        filterMachineStatus: machineStatus,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.machineStatusSelected}
                    options={Array.from(this.state.machineStatusDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Blender
                  </label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    isMulti
                    placeholder=""
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const blender = selected?.map((e: any) => e.value);
                      this.setState({

                        blenderSelected: selected,
                        filterBlender: blender,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.blenderSelected}
                    options={Array.from(this.state.blenderDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.title,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Board
                  </label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    isMulti
                    placeholder=""
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const board = selected?.map((e: any) => e.value);
                      this.setState({
                        boardSelected: selected,
                        filterBoard: board,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.boardSelected}
                    options={Array.from(this.state.boardDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.title,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Site Status</label>
                  <Select
                    ref={this.state.resetStatus}
                    className="react-select"
                    classNamePrefix="my-react-select"
                    name="filterStatus"
                    defaultValue={{
                      label: this.getLabelForStatus(this.state.filterStatus), 
                      value: this.state.filterStatus 
                    }}
                    placeholder=""
                    onChange={(event: any) => {
                      this.setState({
                        filterStatus: event.value,
                      });
                    }}
                    options={Array.from(this.state.statusDB).map((value: any) => {
                      return {
                        value: value.id,
                        label: value.name,
                      };
                    })}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Mechanical hardware version</label>
                  <Select
                    ref={this.state.resetMechanicalHardwareVersion}
                    className="react-select"
                    classNamePrefix="my-react-select"
                    name="filterMechanicalHardwareVersion"
                    placeholder=""
                    onChange={(event: any) => {
                      this.setState({
                        filterMechanicalHardwareVersion: event.value,
                      });
                    }}
                    options={this.state.hardwareVersionsList.map((value: any) => {
                      return {
                        value: value,
                        label: value,
                      };
                    })}
                    defaultValue={[{ value: this.state.filterMechanicalHardwareVersion, label: this.state.filterMechanicalHardwareVersion  }]}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Retired</label>
                  <Select
                    ref={this.state.filterRetired}
                    className="react-select"
                    classNamePrefix="my-react-select"
                    name="filterRetired"
                    defaultValue={{
                      label: this.getLabelForRetired(this.state.filterRetired), 
                      value: this.state.filterRetired
                    }}
                    placeholder=""
                    onChange={(event: any) => {
                      this.setState({
                        filterRetired: event.value,
                      });
                    }}
                    options={[
                      {
                        value: 'only',
                        label: 'Only Retired',
                      },
                      {
                        value: 'exclude',
                        label: 'Without Retired',
                      },
                      {
                        value: 'all',
                        label: 'All',
                      }
                    ]}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Replace new tube doors -1mm shorter
                  </label>
                  <Select
                    ref={this.state.filterReplace}
                    className="react-select"
                    classNamePrefix="my-react-select"
                    name="replaceNewTubeDoors1mmShorter"
                    defaultValue={{
                      label: this.getLabelForReplace(this.state.filterReplace), 
                      value: this.state.filterReplace
                    }}
                    placeholder=""
                    onChange={(event: any) => {
                      this.setState({
                        filterReplace: event.value,
                      });
                    }}
                    options={[
                      {
                        value: 'only',
                        label: 'Only Shorter tubedoor',
                      },
                      {
                        value: 'exclude',
                        label: 'Without Shorter tubedoor',
                      },
                      {
                        value: 'all',
                        label: 'All',
                      }
                    ]}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Has hose clamp?
                  </label>
                  <Select
                    ref={this.state.resetAddHouseClamps}
                    className="react-select"
                    classNamePrefix="my-react-select"
                    name="filterAddHouseClamps"
                    defaultValue={{
                      label: this.getLabelForHasHouseClamp(this.state.filterAddHouseClamps), 
                      value: this.state.filterAddHouseClamps
                    }}
                    placeholder=""
                    onChange={(event: any) => {
                      this.setState({
                        filterAddHouseClamps: event.value,
                      });
                    }}
                    options={Array.from(this.state.addHouseClampsDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Cup Holder
                  </label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    isMulti
                    placeholder=""
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const cupHolder = selected?.map((e: any) => e.value);
                      this.setState({
                        cupHolderSelected: selected,
                        filterCupHolder: cupHolder,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.cupHolderSelected}
                    options={Array.from(this.state.cupHolderDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.title,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fw-bold fs-4">Is VIP</label>
                  <Select
                    ref={this.state.resetIsVip}
                    className="react-select"
                    classNamePrefix="my-react-select"
                    name="filterIsVip"
                    defaultValue={{
                      label: this.getLabelForIsVip(this.state.filterIsVip), 
                      value: this.state.filterIsVip
                    }}
                    placeholder=""
                    onChange={(event: any) => {
                      this.setState({
                        filterIsVip: event.value,
                      });
                    }}
                    options={Array.from(this.state.isVipDB).map((value: any) => {
                      return {
                        value: value.id,
                        label: value.name,
                      };
                    })}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fw-bold fs-4">Is Polyfuses?</label>
                  <Select
                    ref={this.state.resetIsPolyfuses}
                    className="react-select"
                    classNamePrefix="my-react-select"
                    name="filterIsPolyfuses"
                    defaultValue={{
                      label: this.getLabelForIsVip(this.state.filterIsPolyfuses), 
                      value: this.state.filterIsPolyfuses
                    }}
                    placeholder=""
                    onChange={(event: any) => {
                      this.setState({
                        filterIsPolyfuses: event.value,
                      });
                    }}
                    options={Array.from(this.state.isVipDB).map((value: any) => {
                      return {
                        value: value.id,
                        label: value.name,
                      };
                    })}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Mac Address</label>
                    <input
                      autoComplete="off"
                      className={clsx("form-control")}
                      name="filterMacAddress"
                      value={this.state.filterMacAddress}
                      onChange={(event: any) => {
                        this.setState({
                          filterMacAddress: event.target.value,
                        });
                      }}
                    />
                </div>
              </div>
              
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 ">
                  <label className="form-check form-check-custom form-check-solid me-5 d-flex mt-1 align-items-center">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="filterInStock"
                      value={this.state.filterInStock}
                      checked={this.state.filterInStock}
                      onChange={(event: any) => {
                        const { checked } = event.target;
                        if (checked === true) {
                          this.setState({
                            filterInStock: true,
                          });
                        } else {
                          this.setState({
                            filterInStock: false,
                          });
                        }
                      }
                      }
                    />
                    <span
                      className="form-label fw-bold mt-3 ms-2 fs-3"
                      style={{ cursor: "pointer" }}
                    >
                      In Stock
                    </span>
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <button
                    type="submit"
                    onClick={this.searchData}
                    className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                  >
                    {this.state.addLoading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!this.state.addLoading && (
                      <span className="indicator-label">ADD FILTER</span>
                    )}
                  </button>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <button
                    type="submit"
                    onClick={this.removeSearch}
                    className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                  >
                    {this.state.filterLoading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!this.state.filterLoading && (
                      <span className="indicator-label">RESET FILTER</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card mt-9" style={{ zIndex: 0 }}>
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                {/* <h3 className="btn btn-bg-light fw-bold m-0 mb-3 p-0 px-5 py-3 align-items-center card-title text-center">
                  <KTSVG
                    path="/media/icons/duotune/electronics/elc002.svg"
                    className="svg-icon-4 me-1"
                  />
                  Machines
                </h3> */}
                <Columns
                  columnData={this.state.columnData}
                  selectedColumns={this.state.selectedColumns}
                  handleColumnDataSelect={this.handleColumnDataSelect}
                  handleColumnDataSubmit={this.handleColumnDataSubmit}
                  handleSelectAll={this.handleSelectAll}
                />
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                <button
                  onClick={this.handleDownloadExcel}
                  className="btn btn-bg-light btn-active-color-primary fw-bold btn-block w-100 p-0 px-4 py-3 mb-3"
                >
                  {this.state.downloadLoading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please Wait..
                      <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                    </span>
                  )}
                  {!this.state.downloadLoading && (
                    <span className="indicator-label"> <KTSVG
                      path="/media/icons/duotune/files/fil021.svg"
                      className="svg-icon-4 me-2"
                    />
                      Export Excel</span>
                  )}

                </button>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="table-responsive p-0 pb-2 fs-6" id="data-table">
                  <table
                    id="machineTable"
                    className="table align-items-center responsive-table display justify-content-center mb-0 w-100"
                  >
                    <thead>
                      <tr>
                        <th className="machineId text-capitalize font-weight-bolder opacity-7 ps-2">
                          Machine ID
                          <Tooltip anchorSelect=".machineId" className="tooltip-list " data-placement="top">
                          Internal software identifier- not serial number
                          </Tooltip>
                        </th>
                        <th className="locationName text-capitalize font-weight-bolder opacity-7 ps-2">
                          Location Name
                          <Tooltip anchorSelect=".locationName" className="tooltip-list " data-placement="top">
                          Enter the name of the location, followed by dash followed by  the city in which it is situated.
                          </Tooltip>
                        </th>
                        <th className="macAddress text-capitalize font-weight-bolder opacity-7 ps-2">
                          Mac address
                          <Tooltip anchorSelect=".macAddress" className="tooltip-list " data-placement="top">
                          The MAC address is located in the bottom field of the service menu
                          </Tooltip>
                        </th>
                        <th className="serialNumber text-capitalize font-weight-bolder opacity-7 ps-2">
                          Serial Number
                           <Tooltip anchorSelect=".serialNumber" className="tooltip-list " data-placement="top">
                           Find the serial number behind the screen, within the service door compartment.
                          </Tooltip>
                        </th>
                        <th className="machineName text-capitalize font-weight-bolder opacity-7 ps-2">
                          Machine Name
                           <Tooltip anchorSelect=".machineName" className="tooltip-list " data-placement="top">
                           Enter the name of the location, followed by dash followed by  the city in which it is situated.
                          </Tooltip>
                        </th>
                        <th className="deploymentDate text-capitalize font-weight-bolder opacity-7 ps-2">
                          Deployment Date
                           <Tooltip anchorSelect=".deploymentDate" className="tooltip-list " data-placement="top">
                           Date when the machine was installed
                          </Tooltip>
                        </th>
                        <th className="deployedBy text-capitalize font-weight-bolder opacity-7 ps-2">
                          Deployed By
                           <Tooltip anchorSelect=".deployedBy" className="tooltip-list " data-placement="top">
                           Specify the name of the individual from Smoodi who installed the machine. If the installation was done by a third-party company, select the company's name.
                          </Tooltip>
                        </th>
                        <th className="machineStatus text-capitalize font-weight-bolder opacity-7 ps-2">
                          Machine Status
                        </th>
                        <th className="remark text-capitalize font-weight-bolder opacity-7 ps-2">
                          Remark
                           <Tooltip anchorSelect=".remark" className="tooltip-list " data-placement="top">
                           Note pertaining to this machine.
                          </Tooltip>
                        </th>
                        <th className="softwareVersion text-capitalize font-weight-bolder opacity-7 ps-2">
                          Software Version
                           <Tooltip anchorSelect=".softwareVersion" className="tooltip-list " data-placement="top">
                           Displays the software version currently installed on the machine.
                          </Tooltip>
                        </th>
                        <th className="mechanicalHardwareVersion text-capitalize font-weight-bolder opacity-7 ps-2">
                          Mechanical Hardware Version
                           <Tooltip anchorSelect=".mechanicalHardwareVersion" className="tooltip-list " data-placement="top">
                           Displays the version of the machine as determined by its production date.
                          </Tooltip>
                        </th>
                        <th className="replaceNewTubeDoor text-capitalize font-weight-bolder opacity-7 ps-2">
                          Replace new tube doors -1mm shorter
                           <Tooltip anchorSelect=".replaceNewTubeDoor" className="tooltip-list " data-placement="top">
                           Indicates whether the tube door on the machine has been replaced with a shorter version
                          </Tooltip>
                        </th>
                        <th className="isPolyfuses text-capitalize font-weight-bolder opacity-7 ps-2">
                          Is Polyfuses?
                        </th>
                        <th className="createdAt text-capitalize font-weight-bolder opacity-7 ps-2">
                          Created At
                           <Tooltip anchorSelect=".createdAt" className="tooltip-list " data-placement="top">
                           Displays the date and time when the machine was last logged into the portal.
                          </Tooltip>
                        </th>
                        <th className="retiredAt text-capitalize font-weight-bolder opacity-7 ps-2">
                          Retired At
                           <Tooltip anchorSelect=".retiredAt" className="tooltip-list " data-placement="top">
                           Indicates if the machine has gone out of commission and, if so, when this occurred.
                          </Tooltip>
                        </th>
                        <th className="retiredBy text-capitalize font-weight-bolder opacity-7 ps-2">
                          Retired By
                           <Tooltip anchorSelect=".retiredBy" className="tooltip-list " data-placement="top">
                           Identifies the smoodi team member who decommissioned the machine
                          </Tooltip>
                        </th>
                        <th className="blender text-capitalize font-weight-bolder opacity-7 ps-2">
                          Blender
                           <Tooltip anchorSelect=".blender" className="tooltip-list " data-placement="top">
                           Displays the version of the blender assembly installed in the machine
                          </Tooltip>
                        </th>
                        <th className="board text-capitalize font-weight-bolder opacity-7 ps-2">
                          Board
                           <Tooltip anchorSelect=".board" className="tooltip-list " data-placement="top">
                           Displays the version of the adapter board installed in the machine
                          </Tooltip>
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Cup Holder
                        </th>
                        <th className="lastSanitizeAt text-capitalize font-weight-bolder opacity-7 ps-2">
                          Last Sanitize At
                           <Tooltip anchorSelect=".lastSanitizeAt" className="tooltip-list " data-placement="top">
                           Date when the machine was last sanitized and logged to our system
                          </Tooltip>
                        </th>
                        <th className="lastHeartbeatAt text-capitalize font-weight-bolder opacity-7 ps-2">
                          Last Hearbeat At
                           <Tooltip anchorSelect=".lastHeartbeatAt" className="tooltip-list " data-placement="top">
                           the time when the machine was last connected to the Interne
                          </Tooltip>
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Last Sanitizer Replaced At
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Last Filter Replaced At
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Connection Status
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Is VIP
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Site Status
                        </th>
                        <th className="action text-capitalize font-weight-bolder opacity-7 ps-2">
                          Action
                           <Tooltip anchorSelect=".action" className="tooltip-list " data-placement="top">
                           Edit or change the status of the location
                          </Tooltip>
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <div
                    className="dropdown-menu d-block mb-5 "
                    aria-labelledby="dropdownMenuButton"
                  >
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                      id="context-menu"
                    >
                      <li>
                        <button
                          className="dropdown-item editBtn" id="edit_record_link"
                        >
                          <i className="fa fa-pencil fs-6"></i>{" "}
                          <span className="ms-2">Edit</span>
                        </button>
                      </li>

                      <li>
                        {/* eslint-disable-next-line */}
                        <a className="dropdown-item" href="javascript:void(0)" id="view_record_link">
                          <i className="fa fa-eye f-6"></i>
                          <span className="ms-2"> View </span>
                        </a>
                      </li>
                      <li>
                        <button
                          className="dropdown-item qrCodeBtn" id="qrcode_record_link"
                        >
                          <i className="fa fa-qrcode fs-6"></i>{" "}
                          <span className="ms-2">Qr code</span>
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item locationBtn" id="assignlocation_record_link"
                        >
                          <i className="fa-solid fa-location-dot fs-6"></i>{" "}
                          <span className="ms-2">Assign location</span>
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item changeLocationBtn" id="changelocation_record_link"
                        >
                          <i className="fa fa-exchange"></i>{" "}
                          <span className="ms-2">Change Location</span>
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item viewStatusBtn"
                          id="viewStatus_record_link"
                        >
                          <i className="fa fa-display f-6"></i>
                          <span className="ms-2">View Status</span>
                        </button>
                      </li>
                      <li>  
                        {/* eslint-disable-next-line */}
                        <a className="dropdown-item" href="javascript:void(0)" id="createTicket_record_link">
                          <i className="fa fa-ticket f-6"></i>
                          <span className="ms-2"> Create Ticket </span>
                        </a>
                      </li>
                    </ul>
                  </div>

                  {this.state.machineEditModal === true && (
                    <EditMachine
                      currentMachineId={this.state.currentMachineId}
                      machineEditModal={this.state.machineEditModal}
                      handleCloseMachine={this.handleCloseMachine}
                      refreshTable={this.refreshTable}
                    />
                  )}
                  {this.state.changeLocationModal === true && (
                    <ChangeLocationModal
                      changeLocationModal={this.state.changeLocationModal}
                      handleCloseChangeLocation={this.handleCloseChangeLocation}
                      currentMachineId={this.state.currentMachineId}
                      refreshTable={this.refreshTable}
                    />
                  )}
                  {this.state.qrcodeModal === true && (
                    <QrcodeModal
                      qrcodeModal={this.state.qrcodeModal}
                      handleCloseQrcode={this.handleCloseQrcode}
                      currentMachineId={this.state.currentMachineId}
                    />

                  )}

                  {this.state.assignLocationModalOpen === true && (
                    <AssignLocationModal
                      assignLocationModalOpen={this.state.assignLocationModalOpen}
                      handleCloseAssignLocation={this.handleCloseAssignLocation}
                      refreshTable={this.refreshTable}
                      currentMachineId={this.state.currentMachineId}
                    />
                  )}
                  {this.state.viewStatusModelOpen === true && (
                    <ViewStatusModel
                      viewStatusModelOpen={this.state.viewStatusModelOpen}
                      handleCloseViewStatus={this.handleCloseViewStatus}
                      currentMachineId={this.state.currentMachineId}
                      refreshTable={this.refreshTable}
                    />
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </>
    );
  }
}

export default MachinesList;